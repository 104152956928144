import React from 'react'

import SwitchYard from '../../juvo-component/SwitchYard/SwitchYard'
import {
  adjustStdComponentArgs,
  ComponentModel,
  StdComponentArgs,
  WhenChildModel,
  WhenModel,
  whenSelection,
} from '../../../types'
import { containerSetChild, getJuvoInfo } from '../../../store'
import { isUndefined } from '../../../utils'

/**
 * Renders a user branching decision that comes from a paired component (e.g. CheckBoxField, SelectField, etc.)
 */
const When: React.FC<StdComponentArgs<WhenModel>> = props => {
  const { comp, onComponentChange } = props
  const children = comp.tchildren || []
  const whenBranch = whenSelection(comp)

  if (isUndefined(whenBranch)) {
    return <div className="when-no-selection" />
  } else {
    const childIndex = children.findIndex(
      (c: WhenChildModel) => c.when === whenBranch || c.when._inclusive_ === whenBranch,
    )
    const childComp: ComponentModel | null =
      childIndex !== -1 ? children[childIndex] : null
    if (childComp === null) {
      //addins sometimes do not provide all choices, especially with checkbox (nothing on false)
      //this needs to be supported as a valid case
      // console.log("WARNING when no-match", { comp: comp, whenBranch: whenBranch })
      return (
        <div {...getJuvoInfo('When', comp)} className="when-mismatch">
          {/* <Typography variant="h3">Developer Error: invalid when criteria</Typography>       */}
        </div>
      )
    } else {
      const childProps = adjustStdComponentArgs(props, childComp, nc =>
        onComponentChange(containerSetChild(nc, childIndex, comp)),
      )

      return (
        <div {...getJuvoInfo('When', comp)} className="when-selection">
          <SwitchYard key={childIndex} {...childProps} />
        </div>
      )
    }
  }
}

export default When
