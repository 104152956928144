import { createTheme, Theme, ThemeOptions } from '@mui/material'
import { deepmerge } from '@mui/utils'

import { isDefined } from '../../utils'

const defaultTheme: ThemeOptions = {
  typography: {
    allVariants: {
      color: '#290235',
    },
    fontFamily: `'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif`,
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 500,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  palette: {
    primary: {
      main: '#6B337C',
      light: '#A38EB8',
      dark: '#492354',
    },
    secondary: {
      main: '#F3F3F7',
      light: '##f9f9fd',
      dark: '#dcdce0',
    },
    info: {
      main: '#ffffcc',
      light: '#FFFFF5',
      dark: '#FFFF94',
    },
    error: {
      main: '#d32f2f',
      light: '#DA5151',
      dark:'#A72323',
    },
    text: {
      primary: '#290235',
      secondary: '##856686',
      disabled: '#b49ab2',
    },
  },
  components: {
    // Icons in the drawer items take up too much space by default.
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '1.75rem',
        },
      },
    },
    // This is so that we can customize icon color in the parent element.
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          // Match 24px = 3 * 2 + 1.125 * 16
          boxSizing: 'content-box',
          padding: 3,
          fontSize: '1.125rem',
        },
      },
    },
    // To make sure the drawer items and their icons use the default juvo purple.
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#8d6cd9',

            '& .MuiListItemIcon-root': {
              color: 'inherit',
            },
          },
        },
      },
    },
    // This makes the input fields' hover color a dark hue of purple instead of the default black.
    MuiInput: {
      styleOverrides: {
        root: {
          '&:not(.Mui-disabled):hover::before': {
            borderColor: '#502ba6',
          },
        },
      },
    },
    // Tooltip override styles arrow presence is defined in every tooltip implementation
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#6B337C',
          color: '#F3F3F7',
          fontSize: '0.875rem',
        },
        arrow: {
          color: '#6B337C',
        },
      },
    },
    // Override Alert colors
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: '#D9F4D9',
        },
        standardError: {
          backgroundColor: '#ffd0d2',
        },
        standardWarning: {
          backgroundColor: '#F9E3C6',
        },
        standardInfo: {
          backgroundColor: '#E0E0E0',
        },
      },
    },
  },
}

export const createAppTheme = (customTheme?: ThemeOptions): Theme => {
  if (isDefined(customTheme)) {
    return createTheme(deepmerge(defaultTheme, customTheme))
  } else {
    return createTheme(customTheme)
  }
}
