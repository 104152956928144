import { useState, useEffect } from 'react'

export const useLocalStorage = (item: string) => {
  const [favorites, setFavorites] = useState<string[]>([])

  useEffect(() => {
    try {
      const localStorageItem = localStorage.getItem(item)
      const parsedFavorites = !localStorageItem
        ? []
        : JSON.parse(localStorageItem)

      if (!localStorageItem) {
        localStorage.setItem(item, JSON.stringify([]))
      }

      setFavorites(parsedFavorites)
    } catch (_) {
      setFavorites([])
    }
  }, [item])

  const saveFavorites = (newFavorites: string[]) => {
    try {
      const stringifiedFavorites = JSON.stringify(newFavorites)
      localStorage.setItem(item, stringifiedFavorites)
      setFavorites(newFavorites)
    } catch (_) {
      setFavorites([])
    }
  }

  const deleteFavorites = (deleteFavorite: string) => {
    const newFavorites = favorites.filter(
      favorite => favorite !== deleteFavorite,
    )
    saveFavorites(newFavorites)
  }

  return [favorites, saveFavorites, deleteFavorites] as const
}
