import React from 'react'
import { CssBaseline, GlobalStyles, Theme, ThemeOptions, ThemeProvider } from '@mui/material'
import '@fontsource/montserrat/300.css'
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/700.css'
import '@fortawesome/fontawesome-pro/css/all.css'

import { createAppTheme } from './default-theme'
import './AppStyles.scss'

// This function exports the material theme color variables to our global css space as css variables.
// It can be extended if more colors are needed.
const setGlobalStyles = (theme: Theme) => (
  <GlobalStyles
    styles={{
      ':root': {
        '--color-primary': theme.palette.primary.main,
        '--color-primary-light': theme.palette.primary.light,
        '--color-secondary': theme.palette.secondary.main,
        '--color-success': theme.palette.success.main,
        '--color-error': theme.palette.error.main,
        '--color-warning': theme.palette.warning.main,
        '--color-info': theme.palette.info.main,
        '--color-black': theme.palette.text.primary,
        '--color-white': '#FFFFFF',
        '--color-background': '#F9F9FA',
      },
    }}
  />
)

export const AppStyles: React.FC<{
  customTheme?: ThemeOptions
  children: React.ReactNode
}> = ({ customTheme, children }) => {
  const theme = createAppTheme(customTheme)

  return (
    <ThemeProvider theme={theme}>
      {/* Applies the CSS reset to remove browser default styling. */}
      <CssBaseline />
      {setGlobalStyles(theme)}
      {children}
    </ThemeProvider>
  )
}

export type AppTheme = ThemeOptions
