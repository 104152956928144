import React from 'react'

import { ComponentModel } from '../types'
import { validateSingleComponent } from '../store/Validation'

export type ValidationProps = {
  handleVisited: () => void
  errorMessage: string | null
  hasErrors: boolean
}

const MakeValidation: React.FC<{
  component: ComponentModel
  children(props: ValidationProps): JSX.Element
}> = ({ component, children }) => {
  const errors = validateSingleComponent(component)
  const [visited, setVisited] = React.useState(false)
  const hasErrors = visited && errors.length > 0

  return children({
    hasErrors,
    errorMessage: hasErrors ? errors[0].validationMsg : null,
    handleVisited: () => setVisited(true),
  })
}

export default MakeValidation
