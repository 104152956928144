import React from 'react'

import { MakeState } from '../types'

type MakeAdditionalStateProps<T> = {
  initialValue: T
  children(props: MakeState<T>): React.ReactElement
}

const MakeAdditionalState = <T,>({
  initialValue,
  children,
}: MakeAdditionalStateProps<T>): React.ReactElement => {
  const x = React.useState(initialValue)

  return children(x)
}

export default MakeAdditionalState
