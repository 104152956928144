import React from 'react'
import IconButton from '@mui/material/IconButton'
import { Link } from '@mui/material'

import { AppRegistration } from '../../types'
import { sanitizeUrl } from '../../utils'

const DocLink: React.FC<{
  app: AppRegistration
}> = ({ app }) => {
  const appDocLink = app.app_doclink
  // TODO: Check that the host is *web*
  if (appDocLink) {
    return (
      <IconButton
        className="fa-light fa-circle-question"
        component={Link}
        href={sanitizeUrl(appDocLink)}
        target="_blank"
        size="small"
      />
    )
  } else {
    return null
  }
}

export default DocLink
