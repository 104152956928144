import { useMemo } from 'react'
import { v4 as generateUuid } from 'uuid'

import { isUndefined, isUndefinedBool, Nullable, Undefined } from './Undefined'

export type Either<L, R> =
  | { type: 'left'; content: L }
  | { type: 'right'; content: R }

export const right: <L, R>(_: R) => Either<L, R> = r => {
  return { type: 'right', content: r }
}
export const left: <L, R>(_: L) => Either<L, R> = r => {
  return { type: 'left', content: r }
}
export const eitherChain: <L, R1, R2>(
  _1: Either<L, R1>,
  _2: (_: R1) => Either<L, R2>,
) => Either<L, R2> = (e, fn) => {
  if (e.type == 'left') return e
  else return fn(e.content)
}
export const eitherMap: <L, R1, R2>(
  _1: Either<L, R1>,
  _2: (_: R1) => R2,
) => Either<L, R2> = (e, fn) => {
  if (e.type == 'left') return e
  else return right(fn(e.content))
}

//helper needed to avoid casting, R is not in scope inside implementation is type checker gets confused
const filterOutNullsHelper: <R>(_: Nullable<R>[], empty: R[]) => R[] = (
  lst,
  empty,
) => {
  return lst.reduce((acc, el) => {
    if (isUndefined(el)) return acc
    else return [el, ...acc]
  }, empty)
}

export const filterOutNulls: <R>(_: (R | Undefined)[]) => R[] = lst => {
  return filterOutNullsHelper(lst, [])
}

export const assumeRights: <L, R>(_: Either<L, R>[]) => R[] = lst => {
  const res = lst.map(el => {
    if (el.type == 'right') return el.content
    else {
      console.log(`Unexpected Left ${el.content}`)
      return null
    }
  })
  return filterOutNulls(res)
}

export const genGuid = () => {
  return generateUuid()
}

export const curry = <T1, T2, R>(
  fn: (ax: T1, bx: T2) => R,
): ((a: T1) => (b: T2) => R) => {
  return (a: T1) => (b: T2) => fn(a, b)
}

export const curry3 = <T1, T2, T3, R>(
  fn: (ax: T1, bx: T2, cx: T3) => R,
): ((a: T1) => (b: T2) => (c: T3) => R) => {
  return (a: T1) => (b: T2) => (c: T3) => fn(a, b, c)
}

export const pureAsync = <T>(value: T): Promise<T> => {
  return new Promise(resolve => {
    resolve(value)
  })
}

export const isUnit = (a: any): boolean => {
  //this is safe for non-object types too
  return Object.keys(a).length === 0
}

// type hole for code exploration (a.k.a haskell undefined)
export declare function _<T>(): T

export const sanitizeUrl = (url: string): string => {
  if (url.match(/^www\..*$/)) {
    return `https://${url}`
  } else {
    return url
  }
}

export const sanitizeInputName = (label: string): string => {
  return label.toLowerCase().replaceAll(' ', '_')
}

export const getQueryParams = (
  query: string,
): Record<string, string> | null => {
  if (query) {
    const result = {} as Record<string, string>
    new URLSearchParams(query).forEach((value, key) => {
      result[key] = value
    })

    return result
  } else {
    return null
  }
}

export const useQuery = (): Record<string, string> | null => {
  const query = location.search

  return useMemo(() => {
    return getQueryParams(query)
  }, [query])
}

/**
 * Type safer than loash,  Seems to work better.
 */
export const zipWith = <L, R, T>(
  l: Nullable<L[]>,
  r: Nullable<R[]>,
  fn: (a: L, b: R) => T,
): T[] => {
  const useL = l || [] //extra null safety
  const useR = r || []
  const lengthR = useR.length
  const res: T[] = []
  for (let index = 0; index < useL.length; index++) {
    if (index < lengthR) {
      const el = useL[index]
      const er = useR[index]
      res.push(fn(el, er))
    }
  }
  if (lengthR !== useL.length) {
    console.log('zipWith uneven sizes', { left: l, right: r })
  }
  return res
}

export const scrollAppContentToTop = (): void => {
  const appContent = document.querySelector('.app-content')
  appContent?.scrollTo(0, 0)
}

export const getLastPathSegment = (): string => {
  return location.pathname.replace(/^.*\//, '')
}

export const checkBoxTooltipTitle = (val: any, rec: any) => {
  if (isUndefinedBool(val) && isUndefinedBool(rec)) {
    return 'Checkbox value is not set. Click one to select or twice to deselect.'
  } else {
    return ''
  }
}

export const getLocalFavorites = (itemName: string): string[] => {
  const localStorageItem = localStorage.getItem(itemName)
  return !localStorageItem ? [] : (JSON.parse(localStorageItem) as string[])
}
