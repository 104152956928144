import React from 'react'
import { Link } from 'react-router-dom'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'

import { AppId, AppRegistration, Warning } from '../../types'
import { Nullable } from '../../utils'
import { ImmutableMap } from '../../utils/ImmutableMap'
import { getSuggestedApps } from '../../store'

const Suggestions: React.FC<{
  apps: ImmutableMap<AppId, [Warning, AppRegistration]>
  suggestions: Nullable<AppId[]>
}> = ({ apps, suggestions }) => {
  const appList = React.useMemo(() => {
    return getSuggestedApps(apps, suggestions)
  }, [apps, suggestions])

  if (appList.length > 0) {
    return (
      <List>
        {appList.map(app => (
          <ListItem
            button
            component={Link}
            to={`/apps/${app.app_id}`}
            key={app.app_id}
          >
            {app.app_name}
          </ListItem>
        ))}
      </List>
    )
  } else {
    return <Typography variant="h3">No App Suggestions</Typography>
  }
}

export default Suggestions
