import React from 'react'
import { useParams } from 'react-router-dom'
import { match } from 'ts-pattern'

import SwitchYard from '../../components/juvo-component/SwitchYard/SwitchYard'
import Display from '../../components/juvo-component/Display/Display'
import { appRegistrationSetComponent, CustomComponentHandler, getJuvoInfo } from '../../store'
import {
  allocateTabs,
  AppId,
  AppRegistration,
  Command,
  commandMessage,
  ComponentModel,
  Message,
  Warning,
  WarningIssue,
} from '../../types'
import AppNotification from '../../components/juvo-component/AppNotification/AppNotification'
import Loading from '../../components/Loading/Loading'
import { isDefined, isUndefined, Nullable } from '../../utils'
import Dialog from '../../components/Dialog/Dialog'
import Tabs from '../../components/Tabs/Tabs'
import { imGet, ImmutableMap } from '../../utils/ImmutableMap'
import NotFound from '../../components/NotFound/NotFound'

type AddletEffParams = {
  id: string
}

const Addlet: React.FC<{
  customReactComps: CustomComponentHandler
  apps: ImmutableMap<AppId, [Warning, AppRegistration]>
  onAppChange: (appId: AppId, appReg: AppRegistration) => void
  onComponentChange: (appId: AppId) => (c: ComponentModel) => void
  onOutMsg: (msg: Message) => Promise<void>
  globalWarn: Nullable<WarningIssue>
  dismissGlobalWarn: () => void
}> = ({
  customReactComps,
  apps,
  onAppChange,
  onComponentChange,
  onOutMsg,
  globalWarn,
  dismissGlobalWarn
}) => {
  const { id } = useParams<AddletEffParams>() as AddletEffParams
  const app = imGet(apps)(id)
  const handleComponentChange = onComponentChange(id)

  if (isUndefined(app)) {
    return (
      <NotFound message="Invalid app ID."/>
    )
  }

  const [, appReg] = app
  const dispState = appReg.app_skeleton.command?.display_state?.state
  const sendMsgHandler = async (cmd: Command, c?: ComponentModel): Promise<void> => {
    // this just sets the out command
    onAppChange(id, { ...appReg, out_command: cmd })
    // this assumes that all commands are sent out as a message
    // JUVO-844 make redundant adjustment to appReg that is send out
    // modifying app state happens elsewere, this fixes a race condition
    // caused by MakeInputState optimization
    const modappreg = isDefined (c) ? appRegistrationSetComponent(c)(appReg) : appReg
    await onOutMsg(commandMessage(modappreg)(cmd))
  }

  const tabComps = allocateTabs(appReg.app_skeleton)

  const content = match(tabComps)
    .with({ type: 'notabs' }, ({ data }) => (
      <>
        {data.map((comp, idx) => (
          <SwitchYard
            key={idx}
            customReactComps={customReactComps}
            comp={comp}
            onComponentChange={handleComponentChange}
            onCommand={sendMsgHandler}
            appInfo={app}
          />
        ))}
      </>
    ))
    .with({ type: 'tabs' }, ({ data }) => (
      <Tabs
        data={data}
        renderTab={tab => (
          <>
            {tab.components.map((comp, idx) => (
              <SwitchYard
                key={idx}
                customReactComps={customReactComps}
                comp={comp}
                onComponentChange={handleComponentChange}
                onCommand={sendMsgHandler}
                appInfo={app}
              />
            ))}
          </>
        )}
      />
    ))
    .exhaustive()

  return (
    // eslint-disable-next-line react/no-unknown-property
    <div juvo-comp="addlet" juvo-app-id={id} {...getJuvoInfo('addlet', app)}>
      {appReg.app_popup && <Dialog data={appReg.app_popup} />}
      <AppNotification
        appId={id}
        appReg={appReg}
        onAppChange={onAppChange}
        globalWarn={globalWarn}
        dismissGlobalWarn={dismissGlobalWarn}
      />
      <Loading appReg={appReg} />
      {dispState !== 'SuccessFinal' && content}
      {/* TODO back-end sometimes does not send initial */}
      <Display command={appReg.app_skeleton.command || { '@': 'Initial' }} />
    </div>
  )
}

export default Addlet
