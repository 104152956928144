import React from 'react'
import { NavLink } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'

import { getSuggestedApps } from '../../store'
import './Home.scss'
import { ImmutableMap } from '../../utils/ImmutableMap'
import { AppId, AppRegistration, Warning } from '../../types'
import { Nullable } from '../../utils'

import emptySuggestions from './empty-suggestions.svg'
import homeLogo from './home.svg'

const Home: React.FC<{
  apps: ImmutableMap<AppId, [Warning, AppRegistration]>
  suggestions: Nullable<AppId[]>
  onAllAppsClicked: () => void
}> = ({ apps, suggestions, onAllAppsClicked }) => {
  const suggestedApps = React.useMemo(() => {
    return getSuggestedApps(apps, suggestions)
  }, [apps, suggestions])

  return (
    <div className="dashboard">
      {suggestedApps.length > 0 ? (
        <>
          <img
            src={homeLogo}
            alt="recommended applications"
            className="dashboard-logo"
          />
          <Typography variant="h6">Welcome to Juvo!</Typography>
          <Typography variant="body2">
            Your preferred legal platform, crafting custom automation tools to
            accelerate your workflow.
          </Typography>
          <p className="recommended-apps-label">Recommended apps</p>
          <div className="recommended-apps">
            {suggestedApps.map(app => (
              <div key={app.app_id} className="recommended-app">
                <Button
                  component={NavLink}
                  to={`apps/${app.app_id}`}
                  sx={{
                    width: '100%',
                    textTransform: 'capitalize',
                    display: 'flex',
                    justifyContent: 'space-between',
                    ':hover': {
                      '.arrow-icon': {
                        visibility: 'visible',
                      },
                    },
                  }}
                >
                  <div className="recommended-apps-title">
                    <Icon
                      className={app.app_agent?.icon}
                      sx={{
                        color: theme => theme.palette.text.primary,
                      }}
                    />
                    <div className="recommended-app-title">
                      <Typography
                        variant="body2"
                        className="recommended-app-title-text"
                      >
                        {app.app_name}
                      </Typography>
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    className="arrow-icon"
                    sx={{ visibility: 'hidden', minWidth: '55px', p: '6px' }}
                  >
                    <Icon className="fa fa-arrow-right" />
                  </Button>
                </Button>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <img
            src={emptySuggestions}
            alt="no recommendations"
            className="empty-recommendations"
          />
          <Typography variant="h6">Hmm</Typography>
          <Typography variant="body2">
            {`There aren't any recommended apps yet. Go to the All Apps menu to browse.`}
          </Typography>
        </>
      )}
      <Button
        variant="outlined"
        color="primary"
        onClick={onAllAppsClicked}
        className="all-apps-btn"
      >
        {suggestedApps.length > 0 ? 'All Apps' : 'See All Apps'}
      </Button>
    </div>
  )
}

export default Home
