export type Undefined = undefined | null

export type Nullable<T> = T | Undefined

export const isDefined = <T>(value: T | undefined | null): value is T => {
  return value !== undefined && value !== null
}

export const isUndefined = (value: unknown): value is Undefined => {
  return value === undefined || value === null
}

export const isUndefinedBool = (value: unknown): boolean => {
  return value === undefined || value === null
}

export const defv0 =
  <T>(val1: Nullable<T>) =>
  (val2: Nullable<T>): Nullable<T> => {
    return isDefined(val1) ? val1 : val2
  }

export const defv =
  <T>(val1: Nullable<T>) =>
  (val2: T): T => {
    return isDefined(val1) ? val1 : val2
  }
