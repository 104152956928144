import { Map } from 'immutable'

import { Nullable } from '../utils'

export type MLData = object

export type MLFeat = {
  weight: number
  attr: string
  label: string
}

export type MLDataElement = {
  max_features: number
  f1: number
  record_source: object //Map<string, number>
  recall: number
  model_type: string
  precision: number

  top_feats?: Nullable<MLFeat[]>

  max_leaves?: Nullable<number>
  max_depth?: Nullable<number>
  trees?: Nullable<[string, string][]> //Nullable<Map<string, string>>

  date: string
  known_classes?: Nullable<string> //string[] list of classes, comma delimited
  version: number
  instances: number
  validation: number
}

//Please keep these next to the type as they are effectively typing for MLData
export const mlDataMap: (
  _: Nullable<MLData>,
) => Map<string, MLDataElement> = mld => {
  return Map(mld || {})
}

export const mlDataKeys: (_: Nullable<MLData>) => string[] = mld => {
  return Object.keys(mld || {})
}

export const mlDataRecordSource: (
  dataElement: Nullable<MLDataElement>,
) => Map<string, number> = ml => {
  return ml ? Map(ml.record_source) : Map()
}

export const mlDataTrees: (
  _: MLDataElement,
) => Nullable<Map<string, string>> = ml => {
  if (ml.trees && ml.trees.length > 0) {
    return Map(ml.trees)
  } else {
    return null
  }
}

export const mlDataKnownClasses: (
  dataElement?: MLDataElement,
) => string[] = ml => {
  if (ml && ml.known_classes) {
    return ml.known_classes.split(',').map(s => s.trim())
  } else {
    return []
  }
}
