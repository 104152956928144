import React from 'react'
import Typography from '@mui/material/Typography'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Paper from '@mui/material/Paper'

import { MLDataElement } from '../..'

const MLStatsElement: React.FC<{
  statKey: string
  mlDataElement: MLDataElement
}> = ({ statKey, mlDataElement }) => {
  return (
    <>
      <Typography variant="h2">{statKey}</Typography>
      <TableContainer component={Paper}>
        <TableBody>
          <TableRow>
            <TableCell>Model Type</TableCell>
            <TableCell>{mlDataElement.model_type}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Version</TableCell>
            <TableCell>{mlDataElement.version}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>{mlDataElement.date}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Instances</TableCell>
            <TableCell>{mlDataElement.instances}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Precision</TableCell>
            <TableCell>{mlDataElement.precision}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Recall</TableCell>
            <TableCell>{mlDataElement.recall}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>F1</TableCell>
            <TableCell>{mlDataElement.f1}</TableCell>
          </TableRow>
        </TableBody>
      </TableContainer>
    </>
  )
}

export default MLStatsElement
