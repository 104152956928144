import React, { useEffect, useState } from 'react'

export type DisabledWheelProps = {
  handleFocused: (value: boolean) => void
}

const WithDisabledWheelEvent: React.FC<{
  elementHandle: HTMLDivElement | null
  children(props: DisabledWheelProps): JSX.Element
}> = ({ elementHandle, children }) => {
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    if (elementHandle && focused) {
      const onWheel = (event: WheelEvent) => event.preventDefault()
      elementHandle.addEventListener('wheel', onWheel)

      return () => {
        elementHandle.removeEventListener('wheel', onWheel)
      }
    }
  }, [elementHandle, focused])

  return children({
    handleFocused: setFocused,
  })
}

export default WithDisabledWheelEvent
