import React from 'react'

import './AppLoadingSplash.scss'

const AppLoadingSplash: React.FC = () => (
  <svg viewBox="0 0 483 368" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M294.957 236.22C294.957 322.762 239.546 343.258 162.13 343.258C84.7138 343.258 62.2154 341.217 62.2154 254.675C62.2154 168.133 -38.122 17.7822 39.2939 17.7822C116.71 17.7822 294.957 149.678 294.957 236.22Z"
      fill="#F3F3F7"
      fillOpacity="0.5"
    />
    <path
      d="M378.707 324.787H121.717C111.48 324.787 103.151 316.459 103.151 306.222V111.959C103.151 101.722 111.48 93.3936 121.717 93.3936H378.707C388.944 93.3936 397.273 101.722 397.273 111.959V306.222C397.273 316.459 388.944 324.787 378.707 324.787ZM121.717 94.7875C112.248 94.7875 104.545 102.491 104.545 111.959V306.222C104.545 315.69 112.248 323.394 121.717 323.394H378.707C388.176 323.394 395.879 315.69 395.879 306.222V111.959C395.879 102.491 388.176 94.7875 378.707 94.7875H121.717Z"
      fill="#CED0E1"
    />
    <path
      d="M29.1834 286.18C29.1834 286.64 29.5528 287.009 30.0128 287.009H472.791C473.251 287.009 473.62 286.64 473.62 286.18C473.62 285.72 473.251 285.351 472.791 285.351H30.0128C29.5528 285.351 29.1834 285.72 29.1834 286.18Z"
      fill="#290235"
      fillOpacity="0.6"
    />
    <path
      d="M233.011 227.091L210.943 253.599L196.69 241.174L217.606 216.164"
      fill="#D95C5E"
    />
    <path
      d="M217.089 214.824L282.373 215.249L278.408 261.84L216.664 260.707"
      fill="#F15C5E"
    />
    <path
      d="M229.834 266.231L265.096 265.947L264.813 284.924H231.533"
      fill="#D95C5E"
    />
    <path
      d="M258.015 140.619C262.395 140.619 265.945 137.068 265.945 132.688C265.945 128.308 262.395 124.758 258.015 124.758C253.635 124.758 250.084 128.308 250.084 132.688C250.084 137.068 253.635 140.619 258.015 140.619Z"
      fill="#D95C5E"
    />
    <path
      d="M256.398 131.964L252.547 168.297L256.631 168.73L260.482 132.397L256.398 131.964Z"
      fill="#D95C5E"
    />
    <path
      d="M207.034 159.312L293.419 162.852L286.338 209.301L208.733 205.478"
      fill="#F15C5E"
    />
    <path
      d="M265.198 193.061C270.009 193.061 273.91 189.16 273.91 184.349C273.91 179.537 270.009 175.637 265.198 175.637C260.386 175.637 256.485 179.537 256.485 184.349C256.485 189.16 260.386 193.061 265.198 193.061Z"
      fill="white"
    />
    <path
      d="M233.834 190.97C238.646 190.97 242.546 187.07 242.546 182.258C242.546 177.446 238.646 173.546 233.834 173.546C229.022 173.546 225.122 177.446 225.122 182.258C225.122 187.07 229.022 190.97 233.834 190.97Z"
      fill="white"
    />
    <path
      className="hand"
      d="M268.928 218.937L300.072 204.116L307.808 221.369L278.336 235.313"
      fill="#D95C5E"
    />
    <path
      d="M13.083 196.37C12.8975 196.419 12.7393 196.541 12.6431 196.707C12.5469 196.873 12.5205 197.071 12.5697 197.257L32.3302 271.487C32.3799 271.673 32.501 271.831 32.6671 271.927C32.8333 272.024 33.0308 272.05 33.2163 272.001L89.1909 257.074C89.3764 257.025 89.5346 256.903 89.6308 256.737C89.727 256.571 89.7534 256.373 89.7041 256.187L69.9436 181.957C69.894 181.771 69.7729 181.613 69.6067 181.517C69.4406 181.42 69.2431 181.394 69.0575 181.443L13.083 196.37Z"
      fill="#EAE5EB"
    />
    <path
      d="M26.967 207.777C26.1775 207.979 25.7721 208.999 26.0626 210.048C26.3532 211.098 27.2317 211.788 28.0211 211.585L65.2395 202.041C66.029 201.838 66.4345 200.819 66.1439 199.769C65.8533 198.72 64.9749 198.03 64.1854 198.233L26.967 207.777Z"
      fill="white"
    />
    <path
      d="M26.967 217.844C26.1775 218.047 25.772 219.066 26.0626 220.116C26.3532 221.165 27.2317 221.855 28.0211 221.652L65.2395 212.108C66.029 211.906 66.4345 210.887 66.1439 209.837C65.8533 208.787 64.9748 208.097 64.1854 208.3L26.967 217.844Z"
      fill="white"
    />
    <path
      d="M30.3228 224.555C29.5333 224.758 29.1278 225.777 29.4184 226.826C29.7089 227.876 30.5874 228.566 31.3769 228.363L68.5953 218.819C69.3847 218.616 69.7902 217.597 69.4997 216.548C69.2091 215.498 68.3306 214.808 67.5411 215.011L30.3228 224.555Z"
      fill="white"
    />
    <path
      d="M33.6786 234.624C32.8891 234.826 32.4836 235.845 32.7742 236.895C33.0647 237.944 33.9432 238.634 34.7327 238.432L71.9511 228.887C72.7405 228.685 73.146 227.666 72.8555 226.616C72.5649 225.567 71.6864 224.877 70.8969 225.079L33.6786 234.624Z"
      fill="white"
    />
    <path
      d="M36.9511 243.73C36.2275 243.983 35.8558 245.256 36.1221 246.568C36.3885 247.881 37.1938 248.743 37.9174 248.49L72.0343 236.559C72.7579 236.306 73.1297 235.032 72.8633 233.72C72.597 232.408 71.7916 231.546 71.068 231.799L36.9511 243.73Z"
      fill="white"
    />
    <path
      d="M37.0343 251.402C36.2449 251.604 35.8394 252.624 36.1299 253.673C36.4205 254.723 37.299 255.413 38.0885 255.21L75.3069 245.666C76.0963 245.463 76.5018 244.444 76.2113 243.394C75.9207 242.345 75.0422 241.655 74.2527 241.858L37.0343 251.402Z"
      fill="white"
    />
    <path
      d="M16.2778 141.92C16.1113 142.011 15.9869 142.167 15.932 142.352C15.8771 142.537 15.8961 142.738 15.9849 142.909L51.5444 211.234C51.6335 211.405 51.785 211.532 51.9657 211.589C52.1464 211.645 52.3415 211.626 52.5082 211.535L102.775 184.003C102.941 183.912 103.066 183.756 103.121 183.571C103.176 183.386 103.157 183.186 103.068 183.015L67.5083 114.689C67.4192 114.518 67.2677 114.391 67.087 114.334C66.9063 114.278 66.7112 114.298 66.5445 114.389L16.2778 141.92Z"
      fill="#EAE5EB"
    />
    <path
      d="M18.8672 145.531L53.0004 208.265L99.7957 183.749L65.6625 121.015L18.8672 145.531ZM15.9019 147.084L15.9085 147.081L15.9012 147.085L15.9019 147.084Z"
      fill="white"
    />
    <path
      d="M29.9781 151.149C29.2634 151.501 29.1112 152.529 29.6383 153.439C30.1654 154.35 31.1756 154.805 31.8903 154.453L65.5842 137.86C66.2989 137.508 66.4511 136.481 65.924 135.57C65.3969 134.66 64.3866 134.205 63.6719 134.557L29.9781 151.149Z"
      fill="#290235"
    />
    <path
      d="M36.6896 157.86C35.9749 158.212 35.8228 159.24 36.3499 160.15C36.877 161.061 37.8872 161.516 38.6019 161.164L72.2958 144.571C73.0105 144.219 73.1627 143.192 72.6355 142.281C72.1084 141.371 71.0982 140.916 70.3835 141.268L36.6896 157.86Z"
      fill="#290235"
    />
    <path
      d="M40.0454 167.929C39.3307 168.281 39.1786 169.308 39.7057 170.219C40.2328 171.129 41.243 171.584 41.9577 171.232L75.6516 154.64C76.3663 154.288 76.5185 153.26 75.9914 152.35C75.4642 151.439 74.454 150.984 73.7393 151.336L40.0454 167.929Z"
      fill="#290235"
    />
    <path
      d="M43.4012 174.64C42.6865 174.992 42.5343 176.019 43.0614 176.93C43.5885 177.84 44.5988 178.295 45.3135 177.943L79.0073 161.351C79.722 160.999 79.8742 159.971 79.3471 159.06C78.82 158.15 77.8097 157.695 77.095 158.047L43.4012 174.64Z"
      fill="#290235"
    />
    <path
      d="M46.757 184.137C46.0423 184.547 45.8901 185.746 46.4172 186.809C46.9443 187.871 47.9546 188.402 48.6693 187.991L82.3631 168.633C83.0778 168.222 83.23 167.024 82.7029 165.961C82.1758 164.899 81.1655 164.368 80.4508 164.779L46.757 184.137Z"
      fill="#290235"
    />
    <path
      d="M50.1128 190.848C49.3981 191.258 49.2459 192.457 49.773 193.52C50.3001 194.582 51.3104 195.113 52.0251 194.702L85.7189 175.344C86.4336 174.933 86.5858 173.735 86.0587 172.672C85.5316 171.61 84.5213 171.079 83.8066 171.489L50.1128 190.848Z"
      fill="#290235"
    />
    <path
      d="M79.815 237.893C79.8913 238.454 80.108 238.996 80.5862 239.36C81.5617 240.228 82.9649 240.037 83.7115 239.221L110.313 208.878C111.181 207.902 110.99 206.499 110.174 205.753C109.198 204.885 107.795 205.076 107.048 205.892L80.4471 236.234C79.943 236.732 79.7387 237.331 79.815 237.893Z"
      fill="#524257"
    />
  </svg>
)

export default AppLoadingSplash
