import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react'

import ConnectionLostSplash from './ConnectionLostSplash';



const ConnectionLost: React.FC = () => {
    const theme = useTheme();
    return (
        <>
          <Box sx={{
            textAlign: "center"
          }}>
            <Box sx={[
              {width: "484px",
              margin: "40px auto",},
              {[theme.breakpoints.down('md')]: {
                width: "360px"
              }},
              {[theme.breakpoints.down('sm')]: {
                width: "280px"
              }},
              {
                'svg': {'width': '100%'}
              }
            ]}>
            <ConnectionLostSplash />
            </Box>
            <Box sx={{
              margin: "12px 0 24px"
            }}>
                <Typography variant="h2">Connection lost</Typography>
                <Typography variant="h4">Please refresh the page.</Typography>
            </Box>
            <Box sx={{
            margin: "24px 0 0 0"
            }}>
                <Button variant='contained' onClick={() => {location.reload()}}>Refresh</Button>
            </Box>
          </Box>
        </>
      )
}

export default ConnectionLost;
