import React from 'react'
import Paper from '@mui/material/Paper'

import { FilterResultsModel } from '../../../types'

import FilterResult from './FilterResult'



const FilterResults: React.FC<{
  comp: FilterResultsModel
}> = ({ comp }) => {
  const results = comp.tchildren || []
  // const label = 'Filter Results'


  return (
    <>
      {results.map((cp, index) => {
        return (
          <Paper
            key={index}
            elevation={3}          
            sx={{
              margin: theme => theme.spacing(1),
              marginBottom: '15px',
              paddingBottom: '10px',
            }}
          >
            <FilterResult comp={cp} />
          </Paper>
        )
      })}
    </>
  )
}

export default FilterResults
