import { Either, left, right } from './Common'
import { isDefined } from './Undefined'

export type FetchError = {
  errorMsg: string
  status: number
}

export const unexpectedFetchError = (msg: string): FetchError => {
  return {
    errorMsg: msg,
    status: 0,
  }
}

export const getJuvoAuthToken = (): string | null => {
  const token = sessionStorage.getItem('juvoToken')
  if (!isDefined(token)) {
    return null
  } else {
    return token
  }
}

export const setJuvoAuthToken = (token?: string): void => {
  if (!isDefined(token)) {
    console.error('No token received!')
  } else {
    sessionStorage.setItem('juvoToken', token)
  }
}

const mergeQueryParams = (uri: string, token: string): string => {
  const query = new URL(uri).search

  if (query) {
    return `${uri}&token=${token}`
  } else {
    return `${uri}?token=${token}`
  }
}

// fetch itself can fail if network request cannot be completed
export const betterFetchJson = async <T>(
  requestUri: string,
  requestOptions: RequestInit,
): Promise<Either<FetchError, T>> => {
  try {
    const token = getJuvoAuthToken()
    const uri =
      token === null ? requestUri : mergeQueryParams(requestUri, token)
    const res = await fetch(uri, requestOptions)
    if (!res.ok) {
      return left({
        status: res.status,
        errorMsg: res.statusText,
      })
    } else {
      const resContent: T = await res.json()
      return right(resContent)
    }
  } catch (err) {
    console.error({ err, source: requestUri })
    return left(
      unexpectedFetchError(
        `${JSON.stringify({
          err,
          source: requestUri,
        })}: unexpected network err`,
      ),
    )
  }
}
