import React from 'react'
import MuiStepper from '@mui/material/Stepper'
import Box from '@mui/material/Box'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'

import { containerSetChild, getJuvoInfo } from '../../../store'
import {
  adjustStdComponentArgs,
  isStepFinalModel,
  isStepModel,
  StdComponentArgs,
  StepFinalModel,
  StepModel,
  StepperModel,
} from '../../../types'
import { isDefined, Nullable, scrollAppContentToTop } from '../../../utils'
import {
  validateComponentRecursive,
  ValidationError,
} from '../../../store/Validation'
import AppWarnings from '../../juvo-component/AppWarnings/AppWarnings'

import StepContent from './StepContent'
import StepFinalContent from './StepFinalContent'
import './Stepper.scss'

const Stepper: React.FC<{
  stdCompArgs: StdComponentArgs<StepperModel>
  validationState: {
    validationErrs: ValidationError[]
    setValidationErrs: (_: ValidationError[]) => void
  }
}> = ({ stdCompArgs, validationState }) => {
  const props = stdCompArgs
  const { comp, onComponentChange } = stdCompArgs
  const { validationErrs, setValidationErrs } = validationState

  const stepmodels: StepModel[] = (comp.tchildren || []).filter(isStepModel) //this is cool!
  const finalstepmodel: Nullable<StepFinalModel> = (comp.tchildren || []).find(
    isStepFinalModel,
  )
  const finalstepPosition = stepmodels.length // TODO (we need to find index of finalstep in comp.tchildren
  const performValidation = comp.perform_validation || false

  const validModel =
    stepmodels.length > 0 &&
    isDefined(finalstepmodel) &&
    comp.tchildren.indexOf(finalstepmodel) == finalstepPosition
  //active step is the value of the stepper
  //future code will do comp.value || comp.recommendation || 0
  //to enable server based stepping
  const activeStepIdx = comp.value || 0

  const setActiveStep = (n: number): void => {
    onComponentChange({ ...comp, value: n })
  }

  const activeStep =
    activeStepIdx < stepmodels.length ? stepmodels[activeStepIdx] : null

  const activeStepProps = adjustStdComponentArgs(props, activeStep, nc =>
    onComponentChange(containerSetChild(nc, activeStepIdx, comp)),
  )

  const finalStepProps = adjustStdComponentArgs(props, finalstepmodel, nc =>
    onComponentChange(containerSetChild(nc, finalstepPosition, comp)),
  )

  const handleNext = () => {
    const valErrs: ValidationError[] =
      isDefined(activeStep) && performValidation
        ? validateComponentRecursive(activeStep)
        : []
    if (valErrs.length > 0) setValidationErrs(valErrs)
    else {
      setValidationErrs([])
      setActiveStep(activeStepIdx + 1)
    }
  }

  const handleBack = () => {
    setActiveStep(activeStepIdx - 1)
  }

  const handleReset = () => {
    setActiveStep(stepmodels.length - 1)
  }

  React.useEffect(() => {
    scrollAppContentToTop()
  }, [activeStepIdx])

  return validModel ? (
    <Box {...getJuvoInfo('StepperComponent', comp)}>
      <MuiStepper activeStep={activeStepIdx}>
        {stepmodels.map((cp, idx) => {
          return (
            <Step key={idx} completed={idx < activeStepIdx} sx={{
              ".MuiSvgIcon-root": {
                width: "1.6em",
                height: "1.6em"
              }
            }}>
              <StepLabel className="step-label">{cp.label}</StepLabel>
            </Step>
          )
        })}
      </MuiStepper>
      <>
        {activeStep === null ? (
          <div>
            <StepFinalContent {...finalStepProps} />
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button onClick={handleReset}>Back</Button>
              <Box sx={{ flex: '1 1 auto' }} />
            </Box>
          </div>
        ) : (
          <div>
            <StepContent key={activeStepIdx} {...activeStepProps} />

            {validationErrs.length > 0 && (
              <AppWarnings warnings={null} validationErrs={validationErrs} />
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: 2,
              }}
            >
              <Button
                color="inherit"
                disabled={activeStepIdx === 0}
                onClick={handleBack}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />

              <Button onClick={handleNext} onBlur={() => setValidationErrs([])}>
                {activeStepIdx === comp.tchildren.length - 1
                  ? 'Finish'
                  : 'Next'}
              </Button>
            </Box>
          </div>
        )}
      </>
    </Box>
  ) : (
    <h2 {...getJuvoInfo('StepperComponent', comp)}>
      Developer Error, Invalid stepper model{' '}
    </h2>
  )
}

export default Stepper
