import React, { useMemo } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import AppBar from '@mui/material/AppBar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Icon from '@mui/material/Icon'
import Box from '@mui/material/Box'
import { LinearProgress } from '@mui/material'
import clsx from 'clsx'

import {
  AppId,
  AppRegistration,
  AppSkeleton,
  Message,
  Warning,
} from '../../types'
import Eraser from '../../components/Eraser/Eraser'
import MagicWand from '../../components/MagicWand/MagicWand'
import Favorite from '../../components/Favorite/Favorite'
import { getJuvoConfig, getLastPathSegment, useOnSmallScreen } from '../../utils'
import ConnectionStatus from '../../components/ConnectionStatus/ConnectionStatus'
import DocLink from '../../components/DocLink/DocLink'
import './Header.scss'
import { imGet, ImmutableMap } from '../../utils/ImmutableMap'

import MenuIcon from './icons/MenuIcon'
import FeedbackIcon from './icons/FeedbackIcon'
import ProfileIcon from './icons/ProfileIcon'

const Header: React.FC<{
  onDrawerToggled: () => void
  apps: ImmutableMap<AppId, [Warning, AppRegistration]>
  onAppSkelChange: (appId: AppId) => (appsk: AppSkeleton) => void
  onAppRegChange: (appId: AppId) => (appsk: AppRegistration) => void
  onOutMsg: (msg: Message) => Promise<void>
  toggleTestApp: () => void
  isDisconnected: boolean
}> = ({
  onDrawerToggled,
  apps,
  onAppSkelChange,
  onOutMsg,
  toggleTestApp,
  isDisconnected,
}) => {
  const location = useLocation()
  const onSmallScreen = useOnSmallScreen()
  const { headerConfig, feedbackEmail } = getJuvoConfig()

  const appReg = useMemo(() => {
    // HACK: We need to get last path segment, but we cannot use useParams, because header is outside the Route context.
    const appId = getLastPathSegment()
    const app = imGet(apps)(appId)
    if (app === undefined) {
      return null
    } else {
      const [, appReg] = app
      return appReg
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, apps])

  return (
    <AppBar position="relative" className="header">
      <div className="header-content">
        {headerConfig.menu && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                aria-label="menu"
                data-testid="drawer-toggle"
                onClick={onDrawerToggled}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                minWidth: 0,
                '&> p': {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
            >
              {appReg && (
                <>
                  <Icon
                    className={clsx(appReg.app_agent?.icon)}
                    sx={{
                      margin: '0 1rem',
                      width: '1.125rem',
                      height: '1.125rem',
                      fontSize: '0.938rem',
                      // Icon hides on extra small screens
                      display: { xs: 'none', sm: 'block' },
                    }}
                  />
                  <Tooltip
                    arrow
                    title={appReg.app_name + ' ' + appReg.app_version}
                  >
                    <Typography
                      sx={{
                        marginLeft: { xs: '0.25rem', sm: '0rem' },
                        marginRight: { xs: '0.25rem', sm: '1rem' },
                        fontSize: { sm: '1rem', md: '1.25rem' },
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {appReg.app_name}
                    </Typography>
                  </Tooltip>
                  <Typography
                    fontSize="1rem"
                    color="text.secondary"
                    sx={{
                      display: { xs: 'none', sm: 'block' },
                      fontSize: { sm: '0.75rem', md: '1rem' },
                      marginTop: { sm: '0.25rem', md: '0.313rem' },
                    }}
                  >
                    {appReg.app_version}
                  </Typography>
                </>
              )}
            </Box>

            <div className="header-icons">
              {appReg && headerConfig.eraser && (
                <Eraser app={appReg} onAppDataChange={onAppSkelChange} />
              )}
              {appReg && headerConfig.magicWand &&(
                <MagicWand app={appReg} onOutMsg={onOutMsg} />
              )}
              {appReg && headerConfig.docLink && (
                <DocLink aria-label="menu" app={appReg} />
              )}
              {headerConfig.ml && (
                <Tooltip arrow title="Datascience information">
                  <IconButton
                    aria-label="datascience information"
                    className="fa-light fa-flask"
                    component={Link}
                    to="/suggestions"
                    size="small"
                  />
                </Tooltip>
              )}
              {headerConfig.testApp && !onSmallScreen && (
                <Tooltip arrow title="Show Test Application">
                  <IconButton
                    aria-label="show test application"
                    className="fa-light fa-building"
                    onClick={toggleTestApp}
                    size="small"
                  />
                </Tooltip>
              )}
              <Tooltip arrow title="Feedback">
                <IconButton
                  component="a"
                  href={`mailto:${feedbackEmail}?subject=Feedback from ${
                    appReg
                      ? appReg.app_name + ' ' + appReg.app_version
                      : 'Juvo dashboard'
                  }`}
                  target="_blank"
                  aria-label="provide feedback"
                  size="small"
                >
                  <FeedbackIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow title="Account">
                <IconButton
                  component={NavLink}
                  to="/account"
                  aria-label="show account page"
                  size="small"
                >
                  <ProfileIcon />
                </IconButton>
              </Tooltip>
              {headerConfig.connectionStatus && isDisconnected && <ConnectionStatus />}
              {appReg && <Favorite aria-label="menu" app={appReg} />}
            </div>
          </>
        )}
        {isDisconnected && (
          <LinearProgress
            sx={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              right: '0',
            }}
            color="error"
          />
        )}
      </div>
    </AppBar>
  )
}

export default Header
