import React, { Component, ErrorInfo, ReactNode } from 'react'
import Container from '@mui/material/Container'

import './ErrorBoundary.scss'
import NotFound from '../../components/NotFound/NotFound'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  private clearError = () => {
    this.setState({ hasError: false })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Container maxWidth="lg" className="error-container">
          <NotFound message="Something unexpected happened." action={this.clearError}/>
        </Container>
      )
    } else {
      return this.props.children
    }
  }
}

export default ErrorBoundary
