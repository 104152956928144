import React from 'react'
import Typography from '@mui/material/Typography'

import { CritErr } from '../../types'

import authError from './assets/auth-error.svg'
import AppLoadingSplash from './AppLoadingSplash'

import './AppLoading.scss'

// TODO: reconnect button
const AppLoading: React.FC<{ err: CritErr }> = ({ err }) => {
  const isAppBooting = ['Not Initialized', 'initial'].includes(err.msg)
  const isNotLoggedInYet = err.msg === 'Not Logged In' && err.hint === ''

  if (isAppBooting || isNotLoggedInYet) {
    return (
      <div className="app-loading">
        <div className="auth-splash">
          <AppLoadingSplash />
        </div>
        <Typography variant="h2">Hello!</Typography>
        <Typography variant="h4">Welcome to Juvo!</Typography>
      </div>
    )
  }

  return (
    <div className="app-loading">
      <img
        className="auth-error-splash"
        alt="authentication error"
        src={authError}
      />
      <Typography variant="h6">Sorry,</Typography>
      <Typography variant="body2">
        something went wrong with the authentication of your account. Try
        logging in again.
      </Typography>
    </div>
  )
}

export default AppLoading
