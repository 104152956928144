import React from 'react'

const NotFoundSplash: React.FC = () => (
  <svg width="164.68" height="230.31" fill="none" version="1.1" viewBox="0 0 164.68 230.31" xmlns="http://www.w3.org/2000/svg">
  <g transform="translate(-425 29.345)" fill="#f3f3f7">
   <rect transform="rotate(45)" x="373.39" y="-428.23" width="45.646" height="213.93" ry="22.823"/>
   <rect transform="matrix(-.70711 .70711 .70711 .70711 0 0)" x="-344.09" y="289.25" width="45.646" height="213.93" ry="22.823"/>
  </g>
  <g transform="translate(-608.47 -53.443)">
   <g fill="#d95c5e">
    <path d="m733.93 228.29 22.068 26.508 14.253-12.425-20.916-25.01"/>
    <path d="m697.57 265.06 35.262-0.284-0.283 18.977h-33.28"/>
    <path d="m700.51 229.37-22.068 26.508-14.253-12.425 20.916-25.01"/>
   </g>
   <path d="m684.58 217.1 65.284 0.425-3.965 46.591-61.744-1.133" fill="#f15c5e"/>
   <path d="m713.31 144.72-3.8496 24.326 4.084 0.29101 2.9512-18.646 5.2226 6.1582 3.5078-2.1133-7.8398-9.6816 8e-3 -0.0449z" fill="#d95c5e" strokeWidth=".81827"/>
   <path d="m675.94 165.76 86.385 3.54-7.081 46.449-77.605-3.823" fill="#f15c5e"/>
   <path d="m731.03 185.97c-3.1199 1.3257-5.3066 4.4178-5.3066 8.0215 0 4.811 3.9009 8.7109 8.7129 8.7109 4.7381 0 8.5894-3.7838 8.7051-8.4941-1.0455 0.44413-2.1949 0.69141-3.4023 0.69141-4.812-1e-5 -8.7129-3.9019-8.7129-8.7129 0-0.0726 2e-3 -0.14465 4e-3 -0.21679z" fill="#fff"/>
   <path d="m702.83 184.22c3.1199 1.3257 5.3066 4.4178 5.3066 8.0215 0 4.811-3.9009 8.7109-8.7129 8.7109-4.7381 0-8.5894-3.7838-8.7051-8.4941 1.0455 0.44413 2.1949 0.69141 3.4023 0.69141 4.812-1e-5 8.7129-3.9019 8.7129-8.7129 0-0.0726-2e-3 -0.14465-4e-3 -0.21679z" fill="#fff"/>
   <path d="m727.49 166.21c4.38 0 7.93-3.551 7.93-7.931s-3.55-7.93-7.93-7.93-7.931 3.55-7.931 7.93 3.551 7.931 7.931 7.931z" fill="#d95c5e"/>
  </g>
 </svg>
  )

  export default NotFoundSplash;