import { Theme } from '@mui/material'

export const enum ENVIRONMENT {
  development = 'development',
  platform = 'platform',
  staging = 'staging',
  production = 'production',
}

export const getBackgroundByEnvironment = (
  theme: Theme,
  environment: string,
) => {
  switch (environment) {
    case ENVIRONMENT.development:
      return theme.palette.primary.main
    case ENVIRONMENT.platform:
      return theme.palette.text.primary
    case ENVIRONMENT.staging:
      return theme.palette.error.main
    default:
      return '#000000'
  }
}
