import { FetchError } from '../utils'

export type Guid = string
export type AppId = string
export type AppName = string

export type JuvoInfo = {
  'debug-info': string
  'juvo-component': string
  'juvo-msg-type': string
  'juvo-id': string
}

export type CritErr = {
  msg: string
  hint: string
  err: any
}

export const critNetworkErr: (msg: string, _: FetchError) => CritErr = (
  msg,
  fetcherr,
) => {
  return {
    msg: msg,
    hint: fetcherr.errorMsg,
    err: fetcherr,
  }
}

export type WithCritErr<T> =
  | { type: 'succ'; pay: T }
  | { type: 'criterr'; pay: CritErr }
