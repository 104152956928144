import React, { ReactElement } from 'react'
import {
  JuvoApp,
  noCustomComponents,
  notImplementedHandler,
  webLoginHandler,
} from '@juvo/common'

import { printVersionInfo } from './version-info'
import { webAppTheme } from './web-app-theme'
import { useWebContext } from './WebContext'
import './App.scss'

const App = (): ReactElement => {
  const webContext = useWebContext()
  printVersionInfo()

  return (
    <JuvoApp
      customReactComps={noCustomComponents}
      officeCmdHandler={notImplementedHandler}
      ctx={webContext}
      loginHandler={webLoginHandler}
      theme={webAppTheme}
    />
  )
}

export default App
