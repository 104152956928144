import React from 'react'
import MuiAccordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import MuiTextField from '@mui/material/TextField'
import MuiButton from '@mui/material/Button'

import {
  AppId,
  AppSkeleton,
  isAppSkeleton,
  isJuvoMessage,
  JuvoMessage,
  Message,
} from '../../types'
import { isDefined } from '../../utils'
import './Test.scss'

const TestEff: React.FC<{
  onOutMsg: (msg: Message) => Promise<void>
  onInMsg: (msg: Message) => void
  outTestMsgTxt: string
  setOutTestMsgText: (msg: string) => void
  inTestMsgTxt: string
  setInTestMsgText: (msg: string) => void
  testSkelAppId: string | null
  setTestSkelAppId: (_: string | null) => void
  testSkelTxt: string
  setTestSkelTxt: (msg: string) => void
  onAppDataChange: (appId: AppId) => (appsk: AppSkeleton) => void
}> = ({
  onOutMsg,
  onInMsg,
  outTestMsgTxt,
  setOutTestMsgText,
  inTestMsgTxt,
  setInTestMsgText,
  testSkelAppId,
  setTestSkelAppId,
  testSkelTxt,
  setTestSkelTxt,
  onAppDataChange,
}) => {
  // TODO move these out as outTstMsgTxt and inTxtMsgTxt
  // and make this pure
  const [inMsgExpanded, setInMsgExpanded] = React.useState<boolean | null>(null)

  const computedExpandedInMsg =
    inMsgExpanded === null ? '' !== inTestMsgTxt : inMsgExpanded

  const getMsg = (txt: string): Message => {
    const out: JuvoMessage = JSON.parse(txt)
    if (isJuvoMessage(out)) {
      console.log({ testMsg: out })
      return { type: 'msg', payload: out }
    } else {
      console.log({ notAJuvoMsg: out })
      return null
    }
  }

  const setAppSkel = (): void => {
    const appskel = JSON.parse(testSkelTxt)
    if (isAppSkeleton(appskel)) {
      if (isDefined(testSkelAppId)) {
        onAppDataChange(testSkelAppId)(appskel)
        console.log({
          msg: `updated app skel for ${testSkelAppId}`,
          data: appskel,
        })
      } else {
        console.log('Need appId')
      }
    } else {
      console.log('Not a valid app skeleton')
    }
  }

  return (
    <>
      <MuiAccordion>
        <AccordionSummary>Change App Skeleton</AccordionSummary>
        <AccordionDetails className="accordion-details">
          <MuiTextField
            multiline={false}
            variant="outlined"
            type="text"
            value={testSkelAppId}
            onChange={e => setTestSkelAppId(e.target.value)}
            label="AppId"
            className="full-width"
          />
          <MuiTextField
            multiline
            minRows={10}
            variant="outlined"
            type="text"
            value={testSkelTxt}
            onChange={e => setTestSkelTxt(e.target.value)}
            label="Enter New Skeleton:"
            className="full-width"
          />
          <div className="button-container">
            <MuiButton variant="outlined" onClick={() => setAppSkel()}>
              Update Skeleton
            </MuiButton>
            <MuiButton variant="outlined" onClick={() => setOutTestMsgText('')}>
              Clear
            </MuiButton>
          </div>
        </AccordionDetails>
      </MuiAccordion>
      <MuiAccordion>
        <AccordionSummary>Send Out Msg</AccordionSummary>
        <AccordionDetails className="accordion-details">
          <MuiTextField
            multiline
            minRows={10}
            variant="outlined"
            type="text"
            value={outTestMsgTxt}
            onChange={e => setOutTestMsgText(e.target.value)}
            label="Enter WS Out Msg:"
            className="full-width"
          />
          <div className="button-container">
            <MuiButton
              variant="outlined"
              onClick={() => onOutMsg(getMsg(outTestMsgTxt))}
            >
              Send Test WS Msg
            </MuiButton>
            <MuiButton variant="outlined" onClick={() => setOutTestMsgText('')}>
              Clear
            </MuiButton>
          </div>
        </AccordionDetails>
      </MuiAccordion>
      <MuiAccordion
        expanded={computedExpandedInMsg}
        onChange={(e, isExpended) => {
          setInMsgExpanded(isExpended)
        }}
      >
        <AccordionSummary>Mock In Msg</AccordionSummary>
        <AccordionDetails className="accordion-details">
          <MuiTextField
            multiline
            minRows={10}
            variant="outlined"
            type="text"
            value={inTestMsgTxt}
            onChange={e => setInTestMsgText(e.target.value)}
            label="Enter In Msg:"
            className="full-width"
          />
          <div className="button-container">
            <MuiButton
              variant="outlined"
              onClick={() => onInMsg(getMsg(inTestMsgTxt))}
            >
              Mockit!
            </MuiButton>
            <MuiButton variant="outlined" onClick={() => setInTestMsgText('')}>
              Clear
            </MuiButton>
          </div>
        </AccordionDetails>
      </MuiAccordion>
    </>
  )
}

export default TestEff
