import { ComponentModel, InputAction, isUndefined, Nullable } from '..'

/**
 * Enum that represents state from the DisplayState, converted using 'displayReplyState'
 * TODO we should not need this enum
 */
export enum ReplyState {
  Success = 'Success',
  Warning = 'Warning',
  PartialSuccess = 'PartialSuccess',
  Failure = 'Failure',
  Error = 'Error',
  None = 'None',
}

export const displayReplyState = (command: Nullable<Command>): ReplyState => {
  const state = command?.display_state?.state

  if (state === 'Failure') {
    return ReplyState.Failure
  } else if (state === 'Success' || state === 'SuccessFinal') {
    return ReplyState.Success
  } else {
    return ReplyState.None
  }
}

export type Link = {
  link: string
  name: string
}

export type BatchResult = {
  type: 'Warning' | 'Error' | 'Result'
  message: string
}

/**
 * Matches DisplayStates type in lib-message
 */
export type DisplayStates =
  | 'Initial'
  | 'Loading'
  | 'Success'
  | 'SuccessFinal'
  | 'Failure'

export type DisplayState = {
  state: DisplayStates
  download_links?: Nullable<Link[]>
  batch_results?: Nullable<BatchResult[]>
  sub_message?: Nullable<string>
  message?: Nullable<string>
}

export type SubCommand = 'override'

export type Command = {
  '@': string
  sub_command?: Nullable<SubCommand> //set by backend
  frontend_version?: Nullable<string> //set by frontend
  display_state?: Nullable<DisplayState> //set by backend
  value?: Nullable<string> //set by frontend
  token?: Nullable<string>
  internal_loading_state?: Nullable<'loading'> //search for "internal state" to see how this is handled
  internal_dismissed?: Nullable<boolean> //if user has dismissed Dialog Alert
}

export type CommandHandler = {
  'type': 'from-stablestate' 
  'fn': (command: Command) => void
} | {
  'type': 'from-lastminute-changes' 
  'fn': (command: Command, c: ComponentModel) => void
}

export const displayOn = (c: Command): boolean => {
  //TODO we may want to remove all of this as well as the Display component
  // when the dust settles on how things work

  // const action = c['@']
  // const displayState = c.display_state
  // return (
  //   action === 'display' && displayState?.state?.toLowerCase() !== 'initial'
  // )
  return false
}

export const hasLinks = (c: Command): boolean => {
  const links = c.display_state?.download_links
  return Array.isArray(links) && links.length > 0
}

export const hasBatchResults = (c: Command): boolean => {
  const batchResults = c.display_state?.batch_results
  return Array.isArray(batchResults) && batchResults.length > 0
}

export const alertOn = (c: Nullable<Command>): boolean => {
  if (isUndefined(c)) {
    // REVIEW: is this the correct null case?
    return false
  } else {
    //const state = c.display_state?.state?.toLowerCase()
    const action = c['@']
    const displayState = c.display_state
    return (
      action === 'display' && displayState?.state?.toLowerCase() !== 'initial'
    )
    //always display now even if it is success
    //&& (state !== 'success' || hasLinks(c)) || hasBatchResults(c)
  }
}

export const displayDismissed = (c: Nullable<Command>): boolean => {
  return c?.internal_dismissed || false
}

export const dismissDisplay = (c: Command): Command => {
  return { ...c, internal_dismissed: true }
}

export const removeInternals = (command: Command): Command => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const { internal_dismissed, internal_loading_state, ...filteredCommand } =
    command

  return filteredCommand
}

/**
 * Simple messages
 */
export const messageCmd = (value: string): Command => {
  return {
    value,
    '@': 'action',
    token: null,
  }
}

/**
 * Also sets loading state on the command
 */
export const inputActionCmd = (inputAction: InputAction): Command => {
  const internal_loading = inputAction.trigger_loading ? 'loading' : null //Note: internal state handling (do not remove comment)
  return {
    value: inputAction.destination,
    '@': 'action',
    token: null,
    internal_loading_state: internal_loading,
  }
}
