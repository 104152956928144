import React from 'react'
import { SvgIcon } from '@mui/material'

const FeedbackIcon: React.FC = () => (
  <SvgIcon viewBox="0 0 16 16" fontSize="small">
    <svg fill="none">
      <path
        d="M10.3334 5.5C10.6095 5.5 10.8334 5.27614 10.8334 5C10.8334 4.72386 10.6095 4.5 10.3334 4.5V5.5ZM4.66669 5.5H10.3334V4.5H4.66669V5.5Z"
        fill="#290235"
      />
      <path
        d="M7.33335 7.83301C7.6095 7.83301 7.83335 7.60915 7.83335 7.33301C7.83335 7.05687 7.6095 6.83301 7.33335 6.83301V7.83301ZM4.66669 7.83301H7.33335V6.83301H4.66669V7.83301Z"
        fill="#290235"
      />
      <path
        d="M14 10C14 10.3536 13.8595 10.6928 13.6095 10.9428C13.3594 11.1929 13.0203 11.3333 12.6667 11.3333H4.66667L2 14V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H12.6667C13.0203 2 13.3594 2.14048 13.6095 2.39052C13.8595 2.64057 14 2.97971 14 3.33333V10Z"
        stroke="#290235"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)

export default FeedbackIcon
