import clsx from 'clsx'

import { Command, ComponentModel, isRecommended, JuvoInfo } from '../types'
import {
  ComponentEventMap,
  getComponentRecommendation,
  getJuvoInfo,
  mergedComponentEvents,
} from '../store'
import { validateSingleComponent } from '../store/Validation'

import { isDefined } from './Undefined'

export type JuvoProps<E, V> = {
  eventHandlers: ComponentEventMap<E>
  value: V
  className: string
  hasErrors: boolean
  errorMessage: string | null
  juvoInfo: JuvoInfo | Record<string, any>
  recommendation: string
}

export const getJuvoProps = <E, V = any>(
  juvoComponent: string,
  component: ComponentModel,
  onCommand: (cmd: Command) => void,
  changeHandler: (value: E) => void,
): JuvoProps<E, V> => {
  const juvoInfo = getJuvoInfo(juvoComponent, component)

  const eventHandlers = mergedComponentEvents<E>(
    () => component,
    {type:'from-stablestate', fn: onCommand},
    changeHandler,
  )

  const recommendation = getComponentRecommendation(component)
  const value = isDefined(component.value) ? component.value : recommendation

  const errors = validateSingleComponent(component)
  const hasErrors = errors.length > 0

  return {
    eventHandlers,
    juvoInfo,
    hasErrors,
    recommendation,
    value,
    className: clsx(isRecommended(component) && 'juvo-recommendation'),
    errorMessage: hasErrors ? errors[0].validationMsg : null,
  }
}
