import React from 'react'
import TabList from '@mui/lab/TabList'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'

import { TabComponent } from '../../types'

import MakeTabs from './MakeTabs'
import TabPanel from './TabPanel'
import './Tabs.scss'

const Tabs: React.FC<{
  data: TabComponent[]
  renderTab: (tab: TabComponent) => JSX.Element
}> = ({ data, renderTab }) => {
  return (
    <MakeTabs defaultTab="0">
      {({ currentTab, setCurrentTab }) => (
        <TabContext value={currentTab}>
          <TabList
            variant="scrollable"
            onChange={(_: any, newValue: string) => setCurrentTab(newValue)}
            aria-label="tabs"
            // top is negative because of the app-content style, we want to avoid the gap from the tabs and panels
            // values match the responsive design of app-content
            // zIndex is one less than the backdrop
            sx={{
              position: 'sticky',
              top: { xs: '-1rem', sm: '-1rem', md: '-2rem' },
              zIndex: 1098,
              backgroundColor: 'white',
              '.MuiTabs-scrollButtons': {
                display: { xs: 'flex' },
                width: '20px',
              },
            }}
          >
            {data.map(({ tab }, index) => (
              <Tab
                label={tab.tab_name}
                value={`${index}`}
                key={index}
                className="juvo-tab"
              />
            ))}
          </TabList>
          {data.map((tab, index) => (
            <TabPanel value={`${index}`} key={index} sx={{ padding: '1rem 0' }} className="tab-container">
              {renderTab(tab)}
            </TabPanel>
          ))}
        </TabContext>
      )}
    </MakeTabs>
  )
}

export default Tabs
