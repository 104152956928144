import React, { ReactNode, useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { match } from 'ts-pattern'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns'

import Header from '../Header/Header'
import Suggestions from '../../components/Suggestions/Suggestions'
import Addlet from '../Addlet/Addlet'
import Test from '../Test/Test'
import Critical from '../../components/Critical/Critical'
import Drawer from '../../components/Drawer/Drawer'
import {
  bootstrapRes,
  clearState,
  CmdHandler,
  CustomComponentHandler,
  dismissGlobalWarn,
  emptyState,
  getGlobalWarn,
  LoginHandler,
  State,
  stateDebug,
  stateOverApp,
  stateSetComponent,
  stateSetSkeleton,
  updateState,
} from '../../store'
import { AppStyles, AppTheme } from '../AppStyles/AppStyles'
import { new_, user } from '../../network'
import {
  AppId,
  AppRegistration,
  AppSkeleton,
  ComponentModel,
  Context,
  critNetworkErr,
  CtxMessage,
  emptyMsg,
  Message,
  User,
  UtilsWord,
  WithCritErr,
  WordCtx,
} from '../../types'
import AddletStats from '../AddletStats/AddletStats'
import Account from '../../components/Account/Account'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import {
  betterFetchJson,
  curry,
  getJuvoConfig,
  getLastPathSegment,
  getQueryParams,
  isUndefined,
  isDefined,
  Nullable,
  setJuvoAuthToken,
  useOnSmallScreen,
} from '../../utils'
import './JuvoApp.scss'
import Home from '../../components/Home/Home'
import {
  getBackgroundByEnvironment,
  ENVIRONMENT,
} from '../../utils/Environment'
import ConnectionLost from '../../components/ConnectionLost/ConnectionLost'
import { CUSTOM_XML_APP_ID } from '../../constants/Constants'
import ConnectionWarning from '../../components/ConnectionWarning/ConnectionWarning'

import { useWsConnection, WebsocketState } from './WsConnection'

const tokenRoute = 'auth'

/* REVIEW
Custom theme can be passed to JuvoApp as a prop for now, but later on we can change the source to
the backend data, or even the combination of the two.
 */
const JuvoApp: React.FC<{
  customReactComps: CustomComponentHandler
  officeCmdHandler: CmdHandler
  ctx: WithCritErr<Context>
  loginHandler: LoginHandler
  extraComponent?: React.ReactElement
  theme?: AppTheme
  logo?: ReactNode
  utilsWord?: UtilsWord
}> = ({
  customReactComps,
  officeCmdHandler,
  extraComponent,
  ctx,
  loginHandler,
  logo,
  theme,
  utilsWord,
}) => {
  const { basePath, defaultApp, headerConfig, environment } = getJuvoConfig()

  const [apps, setApps] = useState(emptyState)
  const [outMsg, setOutMessage] = useState<Message | CtxMessage>(emptyMsg)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [showTestApp, setShowTestApp] = useState(false)

  //state for Test page
  const [outTestMsgTxt, setOutTestMsgText] = useState('')
  const [inTestMsgTxt, setInTestMsgText] = useState('')

  //TODO consolidate into one
  const [testSkelTxt, setTestSkelTxt] = useState('')
  const [testSkelAppId, setTestSkelAppId] = useState<string | null>('')

  const onSmallScreen = useOnSmallScreen()
  const isNotProduction = environment !== ENVIRONMENT.production

  //TODO https://reactjs.org/docs/integrating-with-other-libraries.html
  //(integrate with context change)
  useEffect(() => {
    if (apps.type === 'success') {
      if (ctx.type === 'succ') {
        console.log('Processing context update', { ctx: ctx })
        setApps(clearState(apps))
        setOutMessage({
          type: 'msgctx',
          payload: { ...ctx.pay, req_id: 'tmp' },
        })
      } else {
        console.log('Unexpected failed replacement context', { err: ctx })
      }
    } else {
      console.log('Initalizing the app', { ctx: ctx })
      const lastPathSegment = getLastPathSegment()
      if (lastPathSegment === tokenRoute) {
        const query = getQueryParams(location.search)
        const token = query?.token
        setJuvoAuthToken(token)
      }
      configure(loginHandler, ctx, setApps)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx, loginHandler]) //changing ctx is only possible in outlook where user selects new email

  //sets outMsg state, so it is available for the WS component
  const outMsgHandler = async (msg: Message) => {
    console.log('outmsg', msg)
    if (msg === null) {
      setOutMessage(msg)
    } else {
      const command = msg.payload?.command
      if (isUndefined(command)) {
        console.log('Unexpected out message without command', { msg: msg })
        setOutMessage(msg)
      } else if (command.value === 'internalsink') {
        console.log('internal test sink message')
      } else {
        const newcommand = { ...command, frontend_version: '1.0.0' }
        const newpayload = { ...msg.payload, command: newcommand }
        setOutMessage({ ...msg, payload: newpayload })
      }
    }
  }

  const elevatedDebug = false

  //handler for processing WebSocket inbound (from server) messages, needed for WebSocketEff component
  const inMsgHandler = (msg: Message): void => {
    if (elevatedDebug) console.log('inMsgHandler - setApps')
    setApps(prevApps => updateState(officeCmdHandler, msg, prevApps))
    // Patent OA Response initialization
    if (
      msg?.payload.app_id === CUSTOM_XML_APP_ID &&
      isDefined(msg?.payload.components) &&
      isDefined(utilsWord) &&
      msg?.payload.command?.['@'] === 'display'
    ) {
      const appData = (ctx.pay as WordCtx).doc_appdata
      const { appHandle, createContext, createComponents } = utilsWord
      if (Object.keys(appData).length === 0) {
        const defaultData = createContext(msg.payload.components)
        appHandle(defaultData)
      } else {
        const newComponents = createComponents(msg.payload.components, appData)
        const newMsg = {
          ...msg,
          payload: { ...msg.payload, components: newComponents },
        }
        setApps(prevApps => updateState(officeCmdHandler, newMsg, prevApps))
      }
    }
  }
  //test version for troubleshooting
  const testInMsgHandler = (msg: Message): void => inMsgHandler(msg)

  const userComponentActionHandler = (appId: AppId) => (c: ComponentModel) => {
    const newApps = stateSetComponent(appId, c, apps)
    if (elevatedDebug)
      console.log('userComponentActionHandler - setApps', {
        c: c,
        apps: stateDebug(newApps),
      })
    setApps(newApps)
  }

  const userSetSkeleton = (appId: AppId) => (appSkel: AppSkeleton) => {
    if (elevatedDebug) console.log('userSetSkeleton - setApps')
    setApps(stateSetSkeleton(appId, appSkel, apps))
  }

  const onAppChange = (appId: AppId, appReg: AppRegistration): void => {
    if (elevatedDebug)
      console.log('onAppChange - setApps', {
        appreg: appReg,
        oldapps: stateDebug(apps),
      })
    setApps(stateOverApp(appId, () => appReg, apps))
  }

  const dismissGlobalWarning = (): void => {
    if (elevatedDebug) console.log('dismissGlobalWarning - setApps')
    setApps(dismissGlobalWarn(apps))
  }

  const websocketState = useWsConnection({
    outMsg,
    guid: apps.type === 'success' ? apps.guid : null,
    inMsg: inMsgHandler,
  })

  const content = match(apps)
    .with({ type: 'success' }, res => {
      return (
        <LocalizationProvider dateAdapter={DateAdapter}>
          <Router basename={basePath}>
            <ErrorBoundary>
              <div className="main-container">
                {logo}
                <Paper
                  className="application-box"
                  elevation={onSmallScreen ? 0 : 6}
                  sx={{
                    // space for the fixed environment bar
                    marginTop: isNotProduction
                      ? '1.5rem'
                      : logo || onSmallScreen
                      ? '0 !important'
                      : '1.5rem',
                  }}
                >
                  <Header
                    onDrawerToggled={() => setDrawerOpen(isOpen => !isOpen)}
                    apps={res.apps}
                    onAppSkelChange={userSetSkeleton}
                    onAppRegChange={curry(onAppChange)}
                    onOutMsg={outMsgHandler}
                    toggleTestApp={() =>
                      setShowTestApp(prevState => !prevState)
                    }
                    isDisconnected={
                      websocketState === WebsocketState.DISCONNECTED
                    }
                  />
                  {headerConfig.menu && (
                    <Drawer
                      appList={res.appList}
                      open={drawerOpen}
                      onClose={() => setDrawerOpen(false)}
                    />
                  )}
                  <div className="app-content">
                    {!showTestApp &&
                      websocketState < WebsocketState.TERMINATED && (
                        <Routes>
                          {/* Navigate just used in the intake portal app, the other apps render the dashboard */}
                          <Route
                            path="/"
                            element={
                              defaultApp ? (
                                <Navigate to={`/apps/${defaultApp}`} replace />
                              ) : (
                                <>
                                  <Home
                                    apps={res.apps}
                                    suggestions={res.suggestions}
                                    onAllAppsClicked={() => setDrawerOpen(true)}
                                  />
                                  <ConnectionWarning isOpen={websocketState === WebsocketState.DISCONNECTED}/>
                                </>
                              )
                            }
                          />
                          <Route
                            path="suggestions"
                            element={
                              <>
                                <Suggestions
                                  apps={res.apps}
                                  suggestions={res.suggestions}
                                />
                                <ConnectionWarning
                                  isOpen={
                                    websocketState ===
                                    WebsocketState.DISCONNECTED
                                  }
                                />
                              </>
                            }
                          />
                          <Route
                            path="apps/:id"
                            element={
                              <>
                                <Addlet
                                  customReactComps={customReactComps}
                                  apps={res.apps}
                                  onAppChange={onAppChange}
                                  onComponentChange={userComponentActionHandler}
                                  onOutMsg={outMsgHandler}
                                  globalWarn={getGlobalWarn(apps)}
                                  dismissGlobalWarn={dismissGlobalWarning}
                                />
                                <ConnectionWarning
                                  isOpen={
                                    websocketState ===
                                    WebsocketState.DISCONNECTED
                                  }
                                />
                                {extraComponent && extraComponent}
                              </>
                            }
                          />
                          <Route
                            path="stats/:id"
                            element={<AddletStats apps={res.apps} />}
                          />
                          <Route
                            path="account"
                            element={<Account user={res.juvoUser} />}
                          />
                          <Route
                            path={tokenRoute}
                            element={
                              <Navigate
                                to="apps/mod-client-intake-portal"
                                replace
                              />
                            }
                          />
                          <Route
                            path="*"
                            element={<Navigate to="/" replace />}
                          />
                        </Routes>
                      )}
                    {showTestApp &&
                      websocketState < WebsocketState.TERMINATED && (
                        <Test
                          onOutMsg={outMsgHandler}
                          onInMsg={testInMsgHandler}
                          outTestMsgTxt={outTestMsgTxt}
                          setOutTestMsgText={setOutTestMsgText}
                          inTestMsgTxt={inTestMsgTxt}
                          setInTestMsgText={setInTestMsgText}
                          testSkelTxt={testSkelTxt}
                          setTestSkelTxt={setTestSkelTxt}
                          testSkelAppId={testSkelAppId}
                          setTestSkelAppId={setTestSkelAppId}
                          onAppDataChange={userSetSkeleton}
                        />
                      )}
                    {websocketState === WebsocketState.TERMINATED && <ConnectionLost/>}
                  </div>
                </Paper>
                {isNotProduction && environment && (
                  <Box
                    sx={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      backgroundColor: theme =>
                        getBackgroundByEnvironment(theme, environment),
                      color: 'white',
                      height: '25px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {`${environment.toUpperCase()} environment`}
                  </Box>
                )}
              </div>
            </ErrorBoundary>
          </Router>
        </LocalizationProvider>
      )
    })
    .with({ type: 'critical' }, res => {
      console.log('setup, WS not started', { err: res.err })
      return <Critical err={res.err} />
    })
    .exhaustive()

  return (
    <AppStyles customTheme={theme}>
      {/*This is needed to measure texts' visual length*/}
      <span id="ruler" />
      {content}
    </AppStyles>
  )
}

const configure = async (
  loginHandler: LoginHandler,
  context: WithCritErr<Context>,
  setApps: React.Dispatch<React.SetStateAction<State>>,
) => {
  if (context.type === 'succ') {
    let usrRes = await user()
    let token: Nullable<string> = null
    console.log('userCall res', { user: usrRes })

    if (usrRes.type == 'left' && usrRes.content.status === 401) {
      //User is not logged in, fetch login flow and retry retrieving user info
      const res = await loginHandler()
      console.log('loginHandler res', { loginRes: res })
      if (res.type === 'cookie') {
        usrRes = await user()
      } else if (res.type === 'user') {
        const usr: User = JSON.parse(res.payload)
        usrRes = {
          type: 'right',
          content: usr,
        }
        token = usr.token
      } else {
        //keep usrRes as error
      }
    } else {
      //keep the same usrRes
    }

    if (usrRes.type == 'left') {
      const lastPathSegment = getLastPathSegment()
      const errorMessage =
        lastPathSegment === tokenRoute ? 'Link is not valid' : 'Not Logged In'
      setApps({
        type: 'critical',
        err: critNetworkErr(errorMessage, usrRes.content),
      })
    } else {
      const nextRes = await new_(token, context.pay)
      const nextState = bootstrapRes(usrRes.content, nextRes)
      setApps(nextState)
      console.log('newCall result', nextState)
    }
  } else {
    setApps({ type: 'critical', err: context.pay })
  }
}

export default JuvoApp
