import { useQuery, WithCritErr } from '@juvo/common'

export type WebContext =
  | {
      '@': string,
      appData: Record<string, string>,
    }
  | Record<string, never>

export const useWebContext = (): WithCritErr<WebContext> => {
  const queryParams = useQuery()

  if (queryParams === null) {
    return {
      type: 'succ',
      pay: {},
    }
  } else {
    return {
      type: 'succ',
      pay: {
        '@': 'web-data',
        appData: queryParams,
      },
    }
  }
}
