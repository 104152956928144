import React from 'react'


const AppLoadingSplash: React.FC = () => (
<svg width="484" height="368" viewBox="0 0 484 368" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_250_13882)">
<path d="M216.585 202.95L195.856 227.849L182.467 216.179L202.115 192.687" fill="#E6E7EF"/>
<path d="M252.454 202.95L273.182 227.849L286.571 216.179L266.923 192.687" fill="#E6E7EF"/>
<path d="M204.248 194.047L265.57 194.446L261.845 238.209L203.849 237.145" fill="#CED0E1"/>
<path d="M216.22 242.333L249.342 242.067L249.076 259.892H217.816" fill="#E6E7EF"/>
<path d="M242.689 124.344C246.803 124.344 250.138 121.009 250.138 116.895C250.138 112.781 246.803 109.446 242.689 109.446C238.575 109.446 235.24 112.781 235.24 116.895C235.24 121.009 238.575 124.344 242.689 124.344Z" fill="#E6E7EF"/>
<path d="M241.171 116.216L237.554 150.344L241.39 150.75L245.007 116.622L241.171 116.216Z" fill="#E6E7EF"/>
<path d="M194.803 141.903L275.945 145.229L269.294 188.859L196.399 185.268" fill="#CED0E1"/>
<path d="M245.508 185.388C250.027 185.388 253.691 181.724 253.691 177.205C253.691 172.685 250.027 169.021 245.508 169.021C240.988 169.021 237.324 172.685 237.324 177.205C237.324 181.724 240.988 185.388 245.508 185.388Z" fill="white"/>
<path d="M217.849 182.982C221.763 185.242 226.768 183.901 229.027 179.987C231.287 176.073 229.946 171.068 226.032 168.808C224.886 172.294 217.849 175.241 214.739 175.241C214.411 177.859 214.739 181.187 217.849 182.982Z" fill="white"/>
<path d="M1 292.504C6.21369 285.049 15.5009 289.355 38.503 292.504C75 297.5 99.5 285.5 106.044 247.119C113.1 205.738 176.5 186.5 207 236.255" stroke="url(#paint0_linear_250_13882)" strokeWidth="7"/>
<path d="M455.181 184.479C441.29 276.469 356.524 302.5 285.96 262.259" stroke="url(#paint1_linear_250_13882)" strokeWidth="7"/>
<rect x="215.379" y="238" width="3.92803" height="19.6401" rx="1.96401" transform="rotate(-47.7861 215.379 238)" fill="#8181A8"/>
<rect x="209.577" y="244.227" width="3.92803" height="19.6401" rx="1.96401" transform="rotate(-47.7861 209.577 244.227)" fill="#8181A8"/>
<path d="M205.692 221.101C206.385 220.331 207.551 220.218 208.379 220.84L223.037 231.848C223.976 232.554 224.108 233.913 223.322 234.786L209.578 250.05C208.8 250.914 207.453 250.935 206.649 250.097L193.97 236.89C193.244 236.134 193.225 234.946 193.927 234.167L205.692 221.101Z" fill="#6B337C"/>
<rect x="224.567" y="230.464" width="7.85606" height="28.8055" rx="2" transform="rotate(42 224.567 230.464)" fill="#290235"/>
<path d="M287.703 273.149C287.281 274.095 286.207 274.563 285.227 274.227L267.888 268.279C266.777 267.897 266.232 266.645 266.71 265.572L275.073 246.812C275.546 245.751 276.821 245.314 277.845 245.863L293.98 254.513C294.904 255.008 295.288 256.133 294.862 257.09L287.703 273.149Z" fill="#6B337C"/>
<rect x="266.857" y="270.066" width="7.85606" height="28.8055" rx="2" transform="rotate(-155.974 266.857 270.066)" fill="#290235"/>
<path d="M228.486 103.881C233.942 99.1887 247.799 93.5367 259.583 108.463" stroke="#CED0E1" strokeWidth="10" strokeLinecap="round"/>
<path d="M221.613 89.4818C228.814 81.2965 256.31 76.3848 270.386 97.01" stroke="#CED0E1" strokeWidth="10" strokeLinecap="round"/>
<path d="M209.491 75.7973C220.576 63.1972 262.902 55.6364 284.57 87.3859" stroke="#CED0E1" strokeWidth="10" strokeLinecap="round"/>
</g>
<defs>
<linearGradient id="paint0_linear_250_13882" x1="157.235" y1="212.92" x2="15.5164" y2="247.304" gradientUnits="userSpaceOnUse">
<stop stopColor="#B599BE"/>
<stop offset="1" stopColor="#B599BE" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_250_13882" x1="430.731" y1="268.227" x2="477" y2="230.5" gradientUnits="userSpaceOnUse">
<stop stopColor="#B599BE"/>
<stop offset="1" stopColor="#B599BE" stopOpacity="0"/>
</linearGradient>
<clipPath id="clip0_250_13882">
<rect width="483" height="368" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>

)

export default AppLoadingSplash