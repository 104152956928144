import React from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Box, Theme } from '@mui/material'

import { BatchResult, Command, displayOn, hasLinks } from '../../../types'
import { getDebugInfo } from '../../../store'
import './Display.scss'

const getColor =
  (theme: Theme) =>
  (displayState?: string): string => {
    switch (displayState) {
      case 'Success':
        return theme.palette.success.main
      case 'Failure':
        return theme.palette.error.main
      default:
        return theme.palette.primary.main
    }
  }

//TODO we may want to centralize these decisions
const getBatchResultItemFontColor = (c: BatchResult): string => {
  switch (c.type) {
    case 'Error':
      return 'rgb(200,20,0);'
    case 'Warning':
      return 'rgb(150,140,0);'
    case 'Result':
      return 'rgb(20,140,0);'
  }
}

const Display: React.FC<{ command: Command }> = ({ command }) => {
  const displayState = command.display_state
  const message = displayState?.message
  const subMessage = displayState?.sub_message
  const isOn = displayOn(command) && !hasLinks(command)
  const batchResults = command?.display_state?.batch_results || []

  return isOn ? (
    <div
      className="display"
      data-debug-info={getDebugInfo(command)}
      data-testid="juvo-display"
    >
      <Paper
        className="display-content"
        elevation={3}
        sx={{ color: theme => getColor(theme)(displayState?.state) }}
      >
        <Typography variant="h6" data-testid="displayState">
          Action: {displayState?.state || 'unknown'}
        </Typography>
        <Typography variant="body1" data-testid="displayMessage">
          Message: {message || 'unknown'}
        </Typography>
        <Typography variant="body2" data-testid="displaySecondaryMessage">
          Secondary message: {subMessage || ''}
        </Typography>
        {batchResults.length > 0 && (
          <Box sx={{ padding: theme => `0 ${theme.spacing(1)}` }}>
            {batchResults.map((batchResult, index) => (
              <Typography
                key={index}
                variant="body2"
                sx={{ color: getBatchResultItemFontColor(batchResult) }}
              >
                {batchResult.message}
              </Typography>
            ))}
          </Box>
        )}
      </Paper>
    </div>
  ) : null
}

export default Display
