import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import MaterialTable from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { IconButton, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { TableModel } from '../../../types'

const Table: React.FC<{
  comp: TableModel
}> = ({ comp }) => {
  const title = comp.title ?? ''
  const tableHeader = comp.withHeaders && comp.value ? comp.value[0] : ''
  const tableData = comp.withHeaders && comp.value ? comp.value.slice(1) : comp.value

  const [isCopied, setIsCopied] = useState(false)

  const copyHandler = () => {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 2500)
  }

  return (
    <Box
      data-testid={`table-${title}`}
      sx={{
        overflowX: 'auto',
        width: '100%',
        position: 'relative',
        '&:hover': {
          '.copy-button': {
            display: 'block',
          },
        },
      }}
    >
      <Typography
        sx={{ textAlign: 'center' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title}
      </Typography>
      <TableContainer component={Paper}>
        <MaterialTable>
          {comp.withHeaders && (
            <TableHead>
              <TableRow>
                {tableHeader.split('\t').map((cell, index) => (
                  <TableCell key={index}>{cell}</TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {tableData && tableData.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {row.split('\t').map((cell, index) => (
                  <TableCell
                    key={index}
                    component="th"
                    scope="row"
                    align="left"
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </MaterialTable>
      </TableContainer>

      <Box
        className="copy-button"
        sx={{ display: 'none', position: 'absolute', top: 0, right: 0 }}
      >
        <CopyToClipboard text={tableData && tableData.join('\n')} onCopy={copyHandler}>
          <Tooltip arrow title={isCopied ? 'Copied' : 'Copy'}>
            <IconButton
              className={`fa-light ${isCopied ? 'fa-check' : 'fa-copy'} `}
              size="small"
              color="primary"
              sx={{
                background: theme => theme.palette.grey[200],
                '&:hover': {
                  background: theme => theme.palette.grey[300],
                },
              }}
            />
          </Tooltip>
        </CopyToClipboard>
      </Box>
    </Box>
  )
}

export default Table
