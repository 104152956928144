import { isUser, User } from '../types'
import {
  betterFetchJson,
  Either,
  eitherChain,
  FetchError,
  getJuvoConfig,
  left,
  right,
  unexpectedFetchError,
} from '../utils'

const checkUser = (res: any): Either<FetchError, User> => {
  if (isUser(res)) {
    return right(res)
  } else {
    console.log({ source: 'checkUser', err: 'User parse err', dta: res })
    return left(unexpectedFetchError('New Result parse err'))
  }
}

export const user = async (): Promise<Either<FetchError, User>> => {
  const { platformHttpUrl } = getJuvoConfig()

  const res = await betterFetchJson(`${platformHttpUrl}/user`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
  })

  return eitherChain(res, checkUser)
}


