import React from 'react'
import IconButton from '@mui/material/IconButton'
import clsx from 'clsx'

import AddletList from '../AddletList/AddletList'
import { AppItem } from '../../types'

import logo from './assets/juvo-logo.svg'
import ArrowLeftIcon from './icons/ArrowLeftIcon'
import './Drawer.scss'

const Drawer: React.FC<{
  appList: AppItem[]
  open: boolean
  onClose: () => void
}> = ({ appList, open, onClose }) => {

  return (
    <>
      <div
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
        className={clsx('juvo-drawer', open && 'juvo-drawer-open')}
        data-testid="app-drawer"
      >
        <div className="drawer-top-bar">
          <IconButton onClick={onClose} size="small">
            <ArrowLeftIcon />
          </IconButton>
        </div>
        <div className="drawer-headline">
          <img src={logo} alt="juvo" />
        </div>
        <div className="menu-container">
          <p className="app-counter-title">All apps ({appList.length})</p>
          <AddletList appList={appList} />
        </div>
      </div>
      <div
        className={clsx(
          'juvo-drawer-overlay',
          open && 'juvo-drawer-overlay-open',
        )}
        onClick={onClose}
      />
    </>
  )
}

export default Drawer
