import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Icon from '@mui/material/Icon'
import clsx from 'clsx'

import './AddletList.scss'

import { AppItem } from '../../types'
import { FAVORITES_NAME } from '../../constants/Constants'
import { getLocalFavorites } from '../../utils'

const AddletList: React.FC<{
  appList: AppItem[]
}> = ({ appList }) => {
  const location = useLocation()
  //TODO temporary if testing ML presentation (low priority move to envvars if we are delaying implementing toggle)
  //TODO ML should be a toggle
  const showML = false
  // reorder list with local favorites
  const orderAppList = sortFavoritesFirst(appList)

  return (
    <>
      <List>
        {orderAppList.map(app => (
          <ListItem key={app.app_id} className="drawer-menu-item">
            <ListItemButton
              className="drawer-menu-item-link"
              data-testid={`item-${app.app_id}`}
              component={Link}
              to={`apps/${app.app_id}`}
              selected={location.pathname.includes(app.app_id)}
              sx={{
                '&.Mui-selected': {
                  boxShadow: theme =>
                    `0px 0px 10px ${theme.palette.primary.light}`,
                },
              }}
            >
              {app.app_agent?.icon && (
                <ListItemIcon
                  className={clsx(app.app_agent?.icon, 'drawer-menu-item-icon')}
                  sx={{
                    color: 'inherit',
                  }}
                />
              )}
              <ListItemText
                primary={app.app_name}
                className="drawer-menu-item-label"
              />
              <span className="drawer-menu-item-cta">
                <Icon className="fa fa-arrow-right" />
              </span>
            </ListItemButton>
            {showML && (
              <ListItemButton component={Link} to={`stats/${app.app_id}`}>
                <ListItemText primary={`(ML)`} />
              </ListItemButton>
            )}
          </ListItem>
        ))}
      </List>
    </>
  )
}

const sortFavoritesFirst = (apps: AppItem[]) => {
  const favoriteApps = getLocalFavorites(FAVORITES_NAME)
  const newFirstApps = apps.filter(item => favoriteApps.includes(item.app_id))
  const newOrderedFirstApps = newFirstApps
    .sort(
      (a, b) => favoriteApps.indexOf(a.app_id) - favoriteApps.indexOf(b.app_id),
    )
    .reverse()
  const latestApps = apps.filter(item => !favoriteApps.includes(item.app_id))
  return [...newOrderedFirstApps, ...latestApps]
}

export default AddletList
