import React from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import {
  AppRegistration,
  Command,
  commandMessage,
  Message,
  messageCmd,
} from '../../types'

const MagicWand: React.FC<{
  app: AppRegistration
  onOutMsg: (msg: Message) => Promise<void>
}> = ({ app, onOutMsg }) => {
  const fnMsg = commandMessage(app)
  const sendMsgHandler = async (cmd: Command): Promise<void> => {
    // TODO: this assumes that all commands are sent out as a message,
    // if this is not the case we need to type guard the command
    // and call some new callback that we pass through the JuvoApp from the office.
    await onOutMsg(fnMsg(cmd))
  }
  return (
    <Tooltip arrow title="Magic wand">
      <IconButton
        aria-label="magic wand"
        className="fa-light fa-wand-magic"
        onClick={() => sendMsgHandler(messageCmd('complete'))}
        size="small"
      />
    </Tooltip>
  )
}

export default MagicWand
