import {
  AppRegistration,
  ComponentModel,
  curry,
  filterOutNulls,
  getComponentValidations,
  IdiosyncraticComponentType,
  isDefined,
  Validation,
} from '..'
import {
  isFileDropModel,
  isIdiosyncraticCompType,
  isListModel,
  isMultiSelectModel,
  isWhenModel,
  WhenChildModel,
  whenSelection,
} from '../types'

export type ValidationError = {
  field_id: string
  field_name: string
  validation: Validation
  validationMsg: string
}
/**
 * To be used in leaf components
 */
export const validateSingleComponent = (
  component: ComponentModel,
): ValidationError[] => {
  const validationsToRun = getComponentValidations(component)

  const results = validationsToRun.map(curry(runSingleValidation)(component))
  return filterOutNulls(results)
}

export const validateApp = (appReg: AppRegistration): ValidationError[] => {
  const componentValidations: ValidationError[][] =
    appReg.app_skeleton.components.map(validateComponentRecursive)
  const empty: ValidationError[] = []
  return empty.concat(...componentValidations)
}

export const validateComponentRecursive = (
  component: ComponentModel,
): ValidationError[] => {
  const compresults: ValidationError[] = validateSingleComponent(component)
  if (isWhenModel(component)) {
    const whenBranch = whenSelection(component)
    if (isDefined(whenBranch)) {
      const children = component.tchildren || []
      const whenbranch = children.find(
        (c: WhenChildModel) => c.when === whenBranch,
      )
      const childres: ValidationError[] = isDefined(whenbranch)
        ? validateSingleComponent(whenbranch)
        : []
      const childrenRes: ValidationError[][] =
        isDefined(whenbranch) && Array.isArray(whenbranch.tchildren)
          ? whenbranch.tchildren.map(validateComponentRecursive)
          : []
      return compresults.concat(...childres).concat(...childrenRes)
    }
  }
  const childrenRes: ValidationError[][] = Array.isArray(component.tchildren)
    ? component.tchildren.map(validateComponentRecursive)
    : []
  return compresults.concat(...childrenRes)
}

const validationMsg = (validation: Validation): string => {
  switch (validation) {
    case 'required': {
      return 'Missing value'
    }
  }
}

const validationErr = (
  component: ComponentModel,
  fieldName: string,
  validation: Validation,
): ValidationError => {
  return {
    field_id: component.id || 'unknown',
    field_name: fieldName,
    validation: validation,
    validationMsg: validationMsg(validation),
  }
}

const runSingleValidation = (
  component: ComponentModel,
  validation: Validation,
): ValidationError | null => {
  switch (validation) {
    case 'required': {
      if (isIdiosyncraticCompType(component.type)) {
        return runRequiredIdiosyncratic(component.type, component, validation)
      } else if (isListModel(component)) {
        if (component.tchildren.length === 0)
          return validationErr(component, component.label, validation)
        else return null
      } else if (
        (isDefined(component.value) && component.value !== '') ||
        isDefined(component.recommendation)
      ) {
        //boolean, etc, components still will not allow component.value !== '' if defined
        // so extra check component.value !== '' is safe
        return null
      } else {
        const fieldName = component.label || component.text || 'unknown'
        return validationErr(component, fieldName, validation)
      }
    }
  }
}

const runRequiredIdiosyncratic = (
  ctype: IdiosyncraticComponentType,
  component: ComponentModel,
  validation: Validation,
): ValidationError | null => {
  switch (ctype) {
    case 'claim-list':
      return null
    case 'file-drop': {
      if (isFileDropModel(component)) {
        if (
          Array.isArray(component.files_raw) &&
          component.files_raw.length > 0
        )
          return null
        else {
          const fieldName = component.text || 'unknown'
          return validationErr(component, fieldName, validation)
        }
      } else {
        console.log({
          errmsg: 'runRequiredIdiosyncratic unexpected file-drop component',
          dta: component,
        })
        return null
      }
    }
    case 'multi-select': {
      if (isMultiSelectModel(component)) {
        if (
          Array.isArray(component.selections) &&
          component.selections.length > 0
        )
          return null
        else {
          const fieldName = component.text || 'unknown'
          return validationErr(component, fieldName, validation)
        }
      } else {
        console.log({
          errmsg: 'runRequiredIdiosyncratic unexpected multi-select component',
          dta: component,
        })
        return null
      }
    }
    case 'text-snippets':
      return null //TODO text-snippets need work
    case 'filter-results':
      return null
  }
}
