import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import { AppRegistration } from '../../types'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { FAVORITES_NAME } from '../../constants/Constants'

const Favorite: React.FC<{
  app: AppRegistration
}> = ({ app }) => {
  const appId = app.app_id
  const [favorites, saveFavorites, deleteFavorite] = useLocalStorage(FAVORITES_NAME)
  const [isFavorite, setIsFavorite] = useState(false)

  useEffect(() => {
    setIsFavorite(favorites.includes(appId))
  }, [favorites, appId])

  const handlerFavorite = () => {
    if (isFavorite) {
      deleteFavorite(appId)
    } else {
      saveFavorites([...favorites, appId])
    }
    setIsFavorite(!isFavorite)
  }

  return (
    <Tooltip
      arrow
      title={isFavorite ? 'Delete from favorites' : 'Make this app favorite'}
    >
      <IconButton
        data-testid={`favorite-btn-${appId}`}
        className={`fa-light ${isFavorite ? 'fa-solid' : ''} fa-heart `}
        onClick={handlerFavorite}
        size="small"
        sx={{ color: theme => theme.palette.primary.main }}
      />
    </Tooltip>
  )
}

export default Favorite
