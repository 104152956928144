import React from 'react'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import clsx from 'clsx'

import { Command, SelectModel } from '../../../types'
import { setComponentValue } from '../../../store'
import {
  getJuvoProps,
  isDefined,
  sanitizeInputName,
  ValidationProps,
} from '../../../utils'

const SelectField: React.FC<{
  comp: SelectModel
  onCommand: (cmd: Command) => void
  onChange: (_: SelectModel) => void
  validationProps: ValidationProps
}> = ({ comp, onCommand, onChange, validationProps }) => {
  const handleChange = (e: SelectChangeEvent<string>) => {
    onChange(setComponentValue(comp)(e.target.value))
  }
  const { eventHandlers, value, juvoInfo } = getJuvoProps(
    'Select',
    comp,
    onCommand,
    handleChange,
  )
  const { onBlur: onBlurFromServer, ...handlersFromServer } = eventHandlers
  const label = comp.text || ''
  const selopts = comp.options.concat(
    comp?.bound?.options?.value || comp?.bound?.options?.recommendation || [],
  )
  const { handleVisited, hasErrors, errorMessage } = validationProps
  const inputName = sanitizeInputName(label)

  return (
    <FormControl
      error={hasErrors}
      variant="standard"
      fullWidth
      className="juvo-input-control"
      {...juvoInfo}
    >
      <InputLabel shrink htmlFor={inputName} className="juvo-input-label">
        {label}
      </InputLabel>
      <Select
        {...handlersFromServer}
        value={value}
        name={inputName}
        id={inputName}
        disableUnderline
        onBlur={() => {
          handleVisited()
          onBlurFromServer && onBlurFromServer()
        }}
        className={clsx(
          'juvo-input-base',
          isDefined(comp.recommendation) &&
            !isDefined(comp.value) &&
            'recommendation',
        )}
        sx={{
          marginTop: '0 !important',
          '.MuiSelect-icon': { right: '28px' },
          '.MuiInputBase-input': { paddingRight: '32px !important' },
        }}
      >
        {selopts.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText sx={{ visibility: hasErrors ? 'visible' : 'hidden' }}>
        {errorMessage || ' '}
      </FormHelperText>
    </FormControl>
  )
}

export default SelectField
