import React from 'react'
import { SvgIcon } from '@mui/material'

const ArrowLeftIcon: React.FC = () => (
  <SvgIcon viewBox="0 0 24 24" fontSize="small">
    <svg fill="none">
      <path
        d="M19 12H5"
        stroke="#667080"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 19L5 12L12 5"
        stroke="#667080"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)

export default ArrowLeftIcon
