import { Alert, Snackbar } from '@mui/material'
import React from 'react'
import Slide from '@mui/material/Slide';

const ConnectionWarning: React.FC<{ isOpen: boolean}> = ({ isOpen }) => {

    return (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={isOpen} sx={{
            width: "calc(100% - 4rem)",
            top: "64px!important",
            position: "absolute",
            }} TransitionComponent={Slide}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    Lost connection. Attempting to re-establish...
                </Alert>
        </Snackbar>
    )
}
export default ConnectionWarning;