import React from 'react'

import rocket from './assets/rocket.svg'
import flame from './assets/flame.svg'
import trail from './assets/trail.svg'
import './RocketFlight.scss'

const RocketFlight: React.FC = () => (
  <div id="rocket-flight">
    <div id="rocket-container">
      <img id="rocket" alt="rocket" src={rocket} />
      <img id="flame" alt="flame" src={flame} />
    </div>
    <div id="star-fade" />
    <div id="star-container">
      <img id="star-1" alt="star" className="star" src={trail} />
      <img id="star-2" alt="star" className="star" src={trail} />
      <img id="star-3" alt="star" className="star" src={trail} />
      <img id="star-4" alt="star" className="star" src={trail} />
      <img id="star-5" alt="star" className="star" src={trail} />
      <img id="star-6" alt="star" className="star" src={trail} />
    </div>
  </div>
)

export default RocketFlight
