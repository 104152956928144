import React from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  TextField,
} from '@mui/material'
import Select from '@mui/material/Select'

import { ClaimView, Command } from '../../../types'
import { getJuvoInfo } from '../../../store'

export type ClaimsTxtChanged = {
  changeType: 'claimsTxtChange'
  payload: ClaimView
}

export type DoneWithChanges = {
  changeType: 'done'
  payload: ClaimView
}

const Claim: React.FC<{
  comp: ClaimView
  onCommand: (cmd: Command) => void
  onChange: (_: ClaimsTxtChanged | DoneWithChanges) => void
}> = ({ comp, onChange }) => {
  const handleStatusChange = (event: any) => {
    onChange({
      changeType: 'done',
      payload: { ...comp, status: event.target.value },
    })
  }

  const handleTypeChange = (event: any) => {
    onChange({
      changeType: 'done',
      payload: { ...comp, isIndependent: JSON.parse(event.target.value) },
    })
  }

  const handleClaimsChange = (event: any) => {
    onChange({
      changeType: 'claimsTxtChange',
      payload: { ...comp, claims: event.target.value },
    })
  }

  const handleClaimsBlur = (event: any) => {
    onChange({
      changeType: 'done',
      payload: { ...comp, claims: event.target.value },
    })
  }

  return (
    <div {...getJuvoInfo('Claim', comp)} className="claim-element">
      <FormControl>
        <TextField
          variant="standard"
          helperText="Claim Number(s)"
          value={comp.claims}
          onChange={handleClaimsChange}
          onBlur={handleClaimsBlur}
        />
        <FormControl
          sx={{ m: { xs: 0, sm: 1 }, minWidth: { xs: '100%', sm: 300 } }}
        >
          <InputLabel id="claim-status-helper-label">Claim Status</InputLabel>
          <Select
            labelId="claims-select-helper-label"
            id="claims-simple-select-helper"
            value={comp.status || ''}
            label="Claim Status"
            onChange={handleStatusChange}
          >
            <MenuItem value={'Original'}>Original</MenuItem>
            <MenuItem value={'Canceled'}>Canceled</MenuItem>
            <MenuItem value={'PreviouslyPresented'}>
              Previously Presented
            </MenuItem>
            <MenuItem value={'Amended'}>Amended</MenuItem>
            <MenuItem value={'Added'}>Added</MenuItem>
            <MenuItem value={'Withdrawn'}>Withdrawn</MenuItem>
          </Select>
          <FormHelperText>Claim Status</FormHelperText>
        </FormControl>
        <FormControl
          sx={{ m: { xs: 0, sm: 1 }, minWidth: { xs: '100%', sm: 300 } }}
        >
          <InputLabel id="claim-type-helper-label">Claim Type</InputLabel>
          <Select
            labelId="claim-type-select-helper-label"
            id="claim-type-select-helper"
            value={`${comp.isIndependent || false}`}
            label="Claim Type"
            onChange={handleTypeChange}
          >
            <MenuItem value="true">Independent</MenuItem>
            <MenuItem value="false">Dependent</MenuItem>
          </Select>
          <FormHelperText>Claim Type</FormHelperText>
        </FormControl>
      </FormControl>
    </div>
  )
}

export default Claim
