import React from 'react'
import Typography from '@mui/material/Typography'
import Icon from '@mui/material/Icon'

import { Base64, Command, FileDropModel } from '../../../types'
import {
  getJuvoInfo,
  mergedComponentEvents,
  setFileDropValue,
} from '../../../store'
import { isDefined } from '../../../utils'

import './FileDrop.scss'
import FileDropArea from './FileDropArea'

//this for loop scales to bigger files
function _arrayBufferToBase64(buffer: Uint8Array) {
  let binary = ''
  const len = buffer.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(buffer[i])
  }
  return btoa(binary)
}

async function getFileContent(file: File): Promise<Base64> {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const result = reader.result as ArrayBuffer
      const fileContentaB64 = _arrayBufferToBase64(new Uint8Array(result))
      resolve(fileContentaB64)
    }
    reader.onerror = () => {
      reject(reader.error)
    }
    reader.readAsArrayBuffer(file)
  })
}

const FileDrop: React.FC<{
  comp: FileDropModel
  onCommand: (cmd: Command) => void
  onChange: (_: FileDropModel) => void
}> = ({ comp, onCommand, onChange }) => {
  // TODO: add validation
  const clearField = () => {
    // REVIEW: this happens when the user clears the file input
    onChange(setFileDropValue(comp)([]))
  }

  const processFiles = async (files: FileList | null): Promise<void> => {
    if (isDefined(files) && isDefined(files[0])) {
      const getAllContents = Array.from(files).map(async file => {
        const fileData = await getFileContent(file)
        return {
          name: file.name,
          contents: fileData,
        }
      })
      const allFiles = await Promise.all(getAllContents)
      onChange(setFileDropValue(comp)(allFiles))
    } else {
      clearField()
    }
  }

  const onChangeHandler = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.currentTarget.files
    await processFiles(files)
  }

  const evAttr: any = mergedComponentEvents(() => comp, {type:'from-stablestate', fn: onCommand}, onChangeHandler)

  const fileDropAttrs: any = {
    multiple: comp.multiple ? true : null,
    accept: comp.accept,
  }

  const removeFile = (filename: string): void => {
    const filtered = comp.files_raw?.filter(f => f.name !== filename)
    onChange(setFileDropValue(comp)(filtered ?? []))
  }

  return (
    <div className="file-input-container" {...getJuvoInfo('file-drop', comp)}>
      {!isDefined(comp.files_raw) ? (
        <div className="file-input-interactions">
          <FileDropArea onFilesDropped={processFiles}>
            <div className="file-input">
              <Icon className="fa-solid fa-arrow-up-from-bracket" />
              <Typography variant="body1">{comp.text}</Typography>
              <input type="file" {...evAttr} {...fileDropAttrs} />
            </div>
          </FileDropArea>
        </div>
      ) : (
        <>
          <p className="files-uploaded-title">Uploaded</p>
          {comp.files_raw.map((file, idx) => (
            <div key={file.name + idx} className="file-input-filled-container">
              <div className="file-input-filled">
                <Typography variant="body1" className="file-name">
                  {file.name}
                </Typography>
                <button
                  aria-label="clear field"
                  className="remove-file-btn"
                  onClick={() => removeFile(file.name)}
                >
                  <Icon className="fa-light fa-trash" />
                </button>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default FileDrop
