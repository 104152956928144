import React, { DragEventHandler } from 'react'

import './FileDropArea.scss'

interface Props {
  onFilesDropped: (files: FileList | null) => void
  children: React.ReactElement
}

const FileDropArea: React.FC<Props> = ({ onFilesDropped, children }) => {
  const handleDrop: DragEventHandler<HTMLDivElement> = event => {
    event.preventDefault()
    event.stopPropagation()
    const files = event.dataTransfer?.files
    onFilesDropped(files)
  }

  return (
    <div
      className="file-drop-area"
      onDrop={handleDrop}
      onDragOver={e => e.preventDefault()}
    >
      {children}
    </div>
  )
}

export default FileDropArea
