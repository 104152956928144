import React from 'react'
import { SvgIcon } from '@mui/material'

const MenuIcon: React.FC = () => (
  <SvgIcon viewBox="0 0 16 17" fontSize="small">
    <circle cx="1.52381" cy="2.02381" r="1.52381" fill="#290235" />
    <circle cx="8.00001" cy="2.02381" r="1.52381" fill="#290235" />
    <circle cx="14.4761" cy="2.02381" r="1.52381" fill="#290235" />
    <circle cx="1.52381" cy="8.49988" r="1.52381" fill="#290235" />
    <circle cx="8.00001" cy="8.49988" r="1.52381" fill="#290235" />
    <circle cx="14.4761" cy="8.49988" r="1.52381" fill="#290235" />
    <circle cx="1.52381" cy="14.976" r="1.52381" fill="#290235" />
    <circle cx="8.00001" cy="14.976" r="1.52381" fill="#290235" />
    <circle cx="14.4761" cy="14.976" r="1.52381" fill="#290235" />
  </SvgIcon>
)

export default MenuIcon
