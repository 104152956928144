import React from 'react'
import Alert, { AlertColor } from '@mui/material/Alert'
import { AlertTitle } from '@mui/material'
import assertNever from 'assert-never'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Icon from '@mui/material/Icon'
import MuiAccordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import IconButton from '@mui/material/IconButton'
import { Box, SxProps, Theme } from '@mui/material'

import {
  alertOn,
  AppId,
  AppRegistration,
  BatchResult,
  dismissDisplay,
  displayDismissed,
  displayReplyState,
  ReplyState,
  WarningIssue,
} from '../../../types'
import { appOverSkeleton } from '../../../store'
import { Nullable } from '../../../utils'
import { CUSTOM_XML_APP_ID } from '../../../constants/Constants'

const getAlertSeverity = (replyState: ReplyState): AlertColor => {
  switch (replyState) {
    case ReplyState.Success:
    case ReplyState.PartialSuccess:
      return 'success'
    case ReplyState.Failure:
    case ReplyState.Error:
      return 'error'
    case ReplyState.Warning:
      return 'warning'
    case ReplyState.None:
      return 'info'
    default:
      assertNever(replyState)
  }
}

//TODO we may want to centralize these decisions
const getBatchResultItemColor = (c: BatchResult): string => {
  switch (c.type) {
    case 'Error':
      return 'rgb(230,40,0);'
    case 'Warning':
      return 'rgb(250,230,0);'
    case 'Result':
      return 'rgb(20,200,0);'
  }
}

const getBatchResultSeverity = (c: BatchResult): AlertColor => {
  switch (c.type) {
    case 'Error':
      return 'error'
    case 'Warning':
      return 'warning'
    case 'Result':
      return 'success'
  }
}

const AppNotification: React.FC<{
  appId: string
  appReg: AppRegistration
  onAppChange: (appId: AppId, appReg: AppRegistration) => void
  globalWarn: Nullable<WarningIssue>
  dismissGlobalWarn: () => void
}> = ({ appReg, appId, onAppChange, globalWarn, dismissGlobalWarn }) => {
  const command = appReg.app_skeleton.command
  const cmdMessage =
    command?.display_state?.message || 'Developer Error (missing msg)'
  const dispState = command?.display_state?.state
  const cmdSubMessage = command?.display_state?.sub_message
  //TODO we currently do not display line breaks correctly
  const bigSubMsg =
    (cmdSubMessage || '').length > 70 ||
    (cmdSubMessage || '').indexOf('\n') !== -1
  const replyState = displayReplyState(command)
  const showCommandAlert = !displayDismissed(command) && alertOn(command)
  const collapseDetails =
    dispState !== 'Success' && dispState !== 'SuccessFinal' && bigSubMsg

  const styles: SxProps<Theme> = {
    position: 'sticky',
    // because of sticky tabs, if tab is not present, the notification is moved a little bit, no harm to UX
    top: { xs: '2rem', sm: '2rem', md: '1rem' },
    overflow: 'auto',
    paddingTop: '0px',
    zIndex: theme => theme.zIndex.snackbar,
    '&:not(:last-child)': {
      marginBottom: theme => theme.spacing(0.5),
    },
    padding: { xs: '8px', sm: '6px 32px 6px 16px' },
    '& .MuiAlert-action': {
      position: 'absolute',
      top: 0,
      right: { xs: '4%', sm: '2%' },
    },
  }

  const handleDismissCommandAlert = (): void => {
    if (command) {
      onAppChange(
        appId,
        appOverSkeleton(
          () => ({
            ...appReg.app_skeleton,
            command: dismissDisplay(command),
          }),
          appReg,
        ),
      )
    }
  }

  const handleDismissGlobalWarnAlert = (): void => {
    if (globalWarn) {
      dismissGlobalWarn()
    }
  }

  const downloadIcon = (nm: string) => {
    const nmLower = nm.toLowerCase().split('.')
    switch (nmLower.pop()) {
      case 'docx':
        return <Icon className="fa fa-file-word" fontSize="small" />
      case 'pdf':
        return <Icon className="fa fa-file-pdf" fontSize="small" />
      case 'xlsx':
        return <Icon className="fa fa-file-excel" fontSize="small" />
      case 'eml':
        return <Icon className="fa fa-envelope" fontSize="small" />
      default:
        return <Icon className="fa fa-file" fontSize="small" />
    }
  }

  // HACK: avoid double Loading component and AppNotification when the command is 'Loading' for custom apps
  if (command?.display_state?.state === 'Loading' && appId === CUSTOM_XML_APP_ID) return null

  if (showCommandAlert) {
    const links = command?.display_state?.download_links || []
    const batchResults = command?.display_state?.batch_results || []

    return (
      <>
        <Alert
          severity={getAlertSeverity(replyState)}
          onClose={
            dispState !== 'SuccessFinal' ? handleDismissCommandAlert : undefined
          }
          sx={styles}
          data-testid="notification"
        >
          <AlertTitle sx={{
            marginTop: 0,
          }}>
            <Typography
              align="left"
              variant="body1"
              data-testid="notificationMessage"
              sx={{
                fontWeight: 500
              }}
            >
              {cmdMessage}
            </Typography>
          </AlertTitle>
          <MuiAccordion
            elevation={0}
            disableGutters={true}
            square={true}
            sx={{
              backgroundColor: 'transparent',
              "&:before" : {
                display: "none"
              }
            }}
          >
            {collapseDetails && (
              <AccordionSummary
                expandIcon={
                  <IconButton
                    className="fa fa-angle-up"
                    size="small"
                    aria-label="open accordion"
                  />
                }
                sx={{
                  flexDirection: 'row-reverse',
                  paddingLeft: '0px',
                }}
              >
                Expand for Details
              </AccordionSummary>
            )}
            <AccordionDetails
              sx={{
                padding: { xs: '8px 0px' },
              }}
            >
              <Typography
                variant="body2"
                data-testid="notificationSecondaryMessage"
                sx={{
                  lineHeight: "1.6"
                }}
              >
                {cmdSubMessage}
              </Typography>
            </AccordionDetails>
          </MuiAccordion>

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              // Use better the mobile white space
              marginLeft: { xs: '-40px', sm: '0px' },
            }}
          >
            {links.map((link, index) => (
              <Button
                key={index}
                variant="outlined"
                href={link.link}
                download={link.name}
                target="_blank"
                startIcon={downloadIcon(link.name)}
                sx={{ margin: theme => theme.spacing(1) }}
              >
                <Tooltip arrow title={link.name} enterDelay={800}>
                  <Box
                    component="span"
                    sx={{
                      maxWidth: { xs: '200px', sm: '330px', md: '700px' },
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {link.name}
                  </Box>
                </Tooltip>
              </Button>
            ))}
          </Box>
          {batchResults.length > 0 && (
            <Box sx={{ padding: theme => `${theme.spacing(2)} 0` }}>
              {batchResults.map((batchResult, index) => (
                <Alert
                  key={index}
                  severity={getBatchResultSeverity(batchResult)}
                  sx={{
                    ...styles,
                    padding: theme => `0 ${theme.spacing(2)}`,
                    opacity: 1,
                    backgroundColor: getBatchResultItemColor(batchResult),
                  }}
                >
                  <Typography variant="body2">{batchResult.message}</Typography>
                </Alert>
              ))}
            </Box>
          )}
        </Alert>
      </>
    )
  } else if (globalWarn) {
    return (
      <Alert
        severity={getAlertSeverity(ReplyState.Failure)}
        onClose={handleDismissGlobalWarnAlert}
        sx={styles}
        data-testid="notification"
      >
        <Typography variant="body1" data-testid="notificationMessage" sx={{
          fontWeight: 500,
          marginBottom: "8px"
        }}>
          {globalWarn.warn}
        </Typography>
        <Typography variant="body2">Technical Details:</Typography>
        <Typography variant="body2" data-testid="notificationSecondaryMessage">
          {JSON.stringify(globalWarn.dta)}
        </Typography>
      </Alert>
    )
  } else {
    return null
  }
}

export default AppNotification
