import React from 'react'
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'

import { AppId, AppRegistration, Warning } from '../../types'
import MLStats from '../../components/MLStats/MLStats'
import { imGet, ImmutableMap } from '../../utils/ImmutableMap'
import { isUndefined } from '../../utils'
import NotFound from '../../components/NotFound/NotFound'

type AddletEffParams = {
  id: string
}

const AddletStats: React.FC<{
  apps: ImmutableMap<AppId, [Warning, AppRegistration]>
}> = ({ apps }) => {
  const { id } = useParams<AddletEffParams>() as AddletEffParams
  const app = imGet(apps)(id)

  if (isUndefined(app)) {
    return <NotFound message="Invalid app ID." />
  }

  const [, appReg] = app

  return (
    // eslint-disable-next-line react/no-unknown-property
    <div juvo-comp="addlet-ml" juvo-app-id={id}>
      <Typography variant="h3">{appReg.app_name}</Typography>
      <MLStats appInfo={appReg} mlData={appReg.app_mldata} />
    </div>
  )
}

export default AddletStats
