import React from 'react'

type InjectedProps = {
  currentTab: string
  setCurrentTab: (newTab: string) => void
}

const MakeTabs: React.FC<{
  defaultTab: string
  children(props: InjectedProps): JSX.Element
}> = ({ defaultTab, children }) => {
  const [currentTab, setCurrentTab] = React.useState(defaultTab)

  return children({ currentTab, setCurrentTab })
}

export default MakeTabs
