//TODO this may need to be better (server needs to send good ids)

import { alpha, SxProps, Theme } from '@mui/material'
import { sha256 } from 'js-sha256'

import {
  AppRegistration,
  ButtonModel,
  CommandHandler,
  ComponentModel,
  ContentHash,
  ContentHashedComponent,
  FileDropModel,
  FileModel,
  getFullComponentType,
  getInputActions,
  InputAction,
  inputActionCmd,
  isButtonsComponent,
  JuvoInfo,
  ListModel,
} from '../types'
import { DebugInfo, genGuid, getJuvoConfig, isDefined, isUndefined } from '../utils'

import { validateApp, ValidationError } from './Validation'

export const getUniqueId = (component: ComponentModel): string => {
  //NO id can cause issues with updating model on user actions
  if (isDefined(component.type) && isUndefined(component.id)) {
    console.log('WARNING - no id', { comp: component })
  }
  if (isButtonsComponent(component)) {
    return component.id + '.buttons' //TODO backend:  IDs not passed originally from fill-skeletons
  }
  return `${component.id || getFullComponentType(component)}.${
    component.text || 'undefined-unique-id'
  }`
}

export const getComponentDebugInfo = (component: ComponentModel): string => {
  return `${getFullComponentType(component)}.${component.text}`
}

export const getComponentLabel = (component: ComponentModel): string => {
  return component.text
}

export const getComponentValue = (component: ComponentModel): string => {
  return component.value || ''
}

export const getComponentRecommendation = (
  component: ComponentModel,
): string => {
  return component.recommendation || ''
}

export const setComponentValue =
  <T = string>(component: ComponentModel) =>
  (value: T | null) => {
    return { ...component, value }
  }

export const setFileDropValue =
  (component: FileDropModel) =>
  (files: FileModel[]): FileDropModel => {
    console.log(files)
    return {
      ...component,
      files_raw: files.length === 0 ? null : files,
    }
  }

export const getDebugInfo = (component: ComponentModel) => {
  return JSON.stringify(component, undefined, 2)
}

export const getJuvoInfo = (
  juvoComponent: string,
  component: ComponentModel,
): JuvoInfo | Record<string, any> => {
  const { showDebugInfo } = getJuvoConfig()
  if (showDebugInfo === DebugInfo.None) {
    return {}
  }

  if (showDebugInfo === DebugInfo.Short) {
    return { 'data-juvo-component': juvoComponent }
  }

  return {
    'data-debug-info': getDebugInfo(component),
    'data-juvo-component': juvoComponent,
    'data-juvo-msg-type': getFullComponentType(component),
    'data-juvo-id': getUniqueId(component), //TODO: server not passing unique ids (e.g. old haskell apps)
  }
}

export const getButtons = (component: ComponentModel): ButtonModel[] => {
  return component.buttons
}

export type EventHandlerMap = {
  // NOTE: we can extend the type with other commonly used handler as needed
  onBlur?: () => void
  onFocus?: () => void
  onKeyUp?: () => void
  onKeyDown?: () => void
  onKeyPress?: () => void
} & {
  [eventName: string]: (...args: any[]) => void
}

export type ComponentEventMap<V> = EventHandlerMap & {
  onChange: (value: V) => void
}

export const getDefinedStyles = (component: ComponentModel, theme: Theme): SxProps<Theme> => {

  const borderStyle = ((): string => {
    const grey = theme.palette.grey[300];
    const green = theme.palette.success.light;
    if (isUndefined(component.render_as)) return "none"
    return `1px solid ${component.render_as === "border-box-success" ? green : grey}`
  })()

  const backgroundStyle = ((): string => {
    const green = alpha(theme.palette.success.light, 0.2);
    if (isUndefined(component.render_as)) return "transparent";
    switch (component.render_as) {
      case "border-box-success":
        return green;
      case "floating-action-bar":
        return theme.palette.secondary.main;
      default:
        return "transparent";
    }
  })()

  const paddingStyle = ((): string => {
    const pad = component.padding ?? 0;
    return `0 ${pad*8}px`
  })()

  const floatingActionBarStyles = component.render_as === "floating-action-bar" ? 
    {
      position: "sticky",
      top: "-28px",
      left: "0",
      right: "0",
      borderRadius: "4px",
      zIndex: "100",
      boxShadow: "0px 5px 3px -1px rgb(0 0 0 / 6%), 0px 9px 10px 0px rgb(0 0 0 / 4%), 0px 6px 12px 0px rgb(0 0 0 / 2%)"
    } : {}

  return {
    border: borderStyle, 
    background: backgroundStyle, 
    padding: paddingStyle,
    ...floatingActionBarStyles
  };

}

export const mergeOnChangeHandler = <V>(
  events: EventHandlerMap | null,
  onChange: (value: V) => void,
): ComponentEventMap<V> => {
  if (events === null) {
    return { onChange }
  } else if (isDefined(events.onChange)) {
    const onChangeFromServer = events.onChange

    return {
      ...events,
      onChange: e => {
        onChange(e)
        onChangeFromServer(e)
      },
    }
  } else {
    return {
      ...events,
      onChange,
    }
  }
}

export const buttonEvents = (
  comp: ComponentModel | ButtonModel,
  onCommand: CommandHandler,
  performValidation: boolean,
  setValidationErrs: (_: ValidationError[]) => void,
  app: AppRegistration,
): EventHandlerMap | null => {
  const events = componentEvents(() => comp, onCommand)
  if (events === null) {
    return events
  }

  if (isDefined(events.onClick)) {
    const onClickFromServer = events.onClick

    const onBlurFromServer = events.onBlur

    return {
      ...events,
      onClick: e => {
        if (performValidation) {
          const errors = validateApp(app)
          setValidationErrs(errors)
          if (errors.length === 0) onClickFromServer(e)
        } else {
          onClickFromServer(e)
        }
      },
      onBlur: () => {
        setValidationErrs([])
        if (isDefined(onBlurFromServer)) {
          onBlurFromServer()
        }
      },
    }
  } else {
    return events
  }
}

export const componentEvents = (
  compfn: () => ComponentModel | ButtonModel,
  onCommand: CommandHandler,
): EventHandlerMap | null => {
  const comp = compfn()
  if (Array.isArray(comp.input_actions)) {
    const inputActions: InputAction[] = getInputActions(comp) || []
    const emptyEventHandlerMap: EventHandlerMap = {}

    return inputActions.reduce((acc, inputAction) => {
      // Note: this sets internal loading state on the command if requested by input Action
      acc[`on${inputAction.event}`] = () => {
        if(onCommand.type == 'from-stablestate') {
          onCommand.fn(inputActionCmd(inputAction)) //there is no easy isButton check
        } else if (onCommand.type == 'from-lastminute-changes') {
          //late bind data from component
          //send message with component specific data
          //that could still be not saved in state (JUVO-844)
          const newcomp = compfn()
          onCommand.fn(inputActionCmd(inputAction), newcomp)
        }
      }

      return acc
    }, emptyEventHandlerMap)
  } else {
    return null
  }
}

export const mergedComponentEvents = <V>(
  compfn: () => ComponentModel,
  onCommand: CommandHandler,
  onChange: (value: V) => void,
): ComponentEventMap<V> => {
  const msgAttr = componentEvents(compfn, onCommand)

  return mergeOnChangeHandler(msgAttr, onChange)
}

export const userDeleteRecommended =
  (contentHash: ContentHash) =>
  (listcomp: ListModel): ListModel => {
    const newRecommends = listcomp.trecommend
      ? listcomp.trecommend.filter(
          childcomp => childcomp.content_hash !== contentHash,
        )
      : []
    const newTIgnore = listcomp.tignore
      ? listcomp.tignore.indexOf(contentHash) === -1
        ? [...listcomp.tignore, contentHash]
        : listcomp.tignore
      : [contentHash]

    //console.log("userDeleteRecommended", { list: listcomp, hash: contentHash, newRec: newRecommends, newtignore: newTIgnore})
    return { ...listcomp, trecommend: newRecommends, tignore: newTIgnore }
  }

export const userAcceptRecommended =
  (contentHash: ContentHash) =>
  (component: ListModel): ListModel => {
    const reccomp = component.trecommend?.find(
      childcomp => childcomp.content_hash === contentHash,
    )
    if (reccomp) {
      const newChildren = [...component.tchildren, reccomp.component]
      const newlist: ListModel = {
        ...component,
        tchildren: newChildren,
        numchildren: newChildren.length,
      }
      return userDeleteRecommended(contentHash)(newlist)
    } else {
      console.log(
        'Err: list-merge hash not found (duplicate item?), dropping component',
        {
          missingHash: contentHash,
          trecommend: JSON.stringify(component.trecommend),
        },
      )
      return component
    }
  }

/**
 * Used in auto-accepting.  If components are identical (same contentHash)
 * they will still be accepted
 */
export const userAcceptRecommendedBestEffort =
  (recommendedComp: ComponentModel) =>
  (contentHash: ContentHash) =>
  (component: ListModel): ListModel => {
    const reccomp = component.trecommend?.find(
      childcomp => childcomp.content_hash === contentHash,
    )
    if (reccomp) {
      const newChildren = [...component.tchildren, reccomp.component]
      const newlist: ListModel = {
        ...component,
        tchildren: newChildren,
        numchildren: newChildren.length,
      }
      return userDeleteRecommended(contentHash)(newlist)
    } else {
      console.log(
        'Warn: list-merge hash not found (duplicate item?), reinstating component',
        {
          missingHash: contentHash,
          trecommend: JSON.stringify(component.trecommend),
        },
      )
      const newChildren = [...component.tchildren, recommendedComp]
      const newlist: ListModel = {
        ...component,
        tchildren: newChildren,
        numchildren: newChildren.length,
      }
      //the hash has aleady been removed, no need to call userDeleteRecommended
      return newlist
    }
  }

/**
 * Description stub
 * @param component
 */
export const addIdsToCompIfMissing = (
  component: ComponentModel,
): ComponentModel => {
  const tchildren = Array.isArray(component.tchildren)
    ? component.tchildren.map(addIdsToCompIfMissing)
    : null
  const componentWithId = component.id
    ? component
    : { ...component, id: genGuid() }

  return tchildren
    ? { ...componentWithId, tchildren: tchildren }
    : componentWithId
}

/**
 * Description stub
 * @param component
 */
export const removeIdsFromComp = (
  component: ComponentModel,
): ComponentModel => {
  const tchildren = Array.isArray(component.tchildren)
    ? component.tchildren.map(removeIdsFromComp)
    : null

  const componentWithoutId = component.id
    ? { ...component, id: null }
    : component

  return tchildren
    ? { ...componentWithoutId, tchildren: tchildren }
    : componentWithoutId
}

export const contentHash = (component: ComponentModel): ContentHash => {
  const sanitized = removeIdsFromComp(component)

  return sha256(JSON.stringify(sanitized))
}

/**
 * Retrieve new recommendations from the message.
 * @param ignoreHashes: hashes that the function ignores
 */
export const msgListRecommendations =
  (ignoreHashes: ContentHash[]) =>
  (messagelist: ListModel): ContentHashedComponent[] => {
    return messagelist.tchildren.reduce((acc, msgcomp) => {
      if (msgcomp.id) return acc
      else {
        const ch = contentHash(msgcomp)
        if (ignoreHashes.indexOf(ch) > -1) {
          return acc
        } else {
          return [
            ...acc,
            { content_hash: ch, component: addIdsToCompIfMissing(msgcomp) },
          ]
        }
      }
    }, [])
  }

export const removeDuplicateHashes = (
  compList: ContentHashedComponent[],
): ContentHashedComponent[] => {
  const result = compList.reduce(
    (acc, hashedcomp) => {
      if (acc.ignoreHashes.indexOf(hashedcomp.content_hash) > -1) {
        console.log("Warn: Removing duplicate hashed component", {hashcomp: hashedcomp}) 
        return acc
      } else {
        const newHashes: string[] = [
          ...acc.ignoreHashes,
          hashedcomp.content_hash,
        ]
        const newRes: ContentHashedComponent[] = [
          ...acc.res,
          hashedcomp,
        ]
        return { ignoreHashes: newHashes, res: newRes }
      }
    },
    { ignoreHashes: [] as string[], res: [] as ContentHashedComponent[] },
  )
  return result.res
}
