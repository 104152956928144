import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'

import SwitchYard from '../../juvo-component/SwitchYard/SwitchYard'
import {
  adjustStdComponentArgs,
  ColumnModel,
  ComponentModel,
  StdComponentArgs,
} from '../../../types'
import { containerSetChild, getDefinedStyles, getJuvoInfo } from '../../../store'
import { isUndefined } from '../../../utils'

const Columns: React.FC<StdComponentArgs<ColumnModel>> = props => {
  const { comp, onComponentChange } = props
  const children: ComponentModel[] = comp.tchildren || []
  const columns = comp.columns || 1

  return (
    <GridContainer comp={comp} >
      <>
        {comp.label && <GridItemLabel label={comp.label}/>}
        {children.map((cp, index) => {
          const childProps = adjustStdComponentArgs(props, cp, nc =>
            onComponentChange(containerSetChild(nc, index, comp)),
          )

          return (
            <Grid
              key={index}
              item
              xs={12}
              md={12 / columns}
              // override default MUI padding for grid items
              sx={{ paddingLeft: '0px !important', paddingRight: '16px' }}
            >
              <SwitchYard key={index} {...childProps} />
            </Grid>
          )
        })}
      </>
    </GridContainer>
  )
}

const GridContainer: React.FC<{
  comp: ColumnModel
  children: React.ReactElement
}> = ({ children, comp }) => {
  const theme = useTheme()
  const styles = getDefinedStyles(comp, theme)

  const align = (() => {
    if (isUndefined(comp.alignment)) return null
    else if (comp.alignment === 'vertical') return { 
      alignItems: 'center' ,
      '&.floating-action-bar .MuiGrid-item': {
        paddingTop: 0
      }
    }
  })()

  return (
    <Grid
      container
      spacing={2}
      className={comp.render_as ?? ""}
      {...getJuvoInfo('FolderCont', comp)}
      sx={{
        marginLeft: comp.indent ? `${comp.indent * 10}px` : 0,
        width: '100%',
        ...align,
        ...styles,
      }}
    >
      {children}
    </Grid>
  )
}

const GridItemLabel: React.FC<{
  label: string
}> = ({ label }) => {
  return (
    <Grid
      item
      xs={12}
      // override default MUI padding for grid items
      sx={{ paddingLeft: '0px !important' }}
    >
      <Typography variant="body1">{label}</Typography>
    </Grid>
  )
}

export default Columns
