import React, { useEffect } from 'react'
import Paper from '@mui/material/Paper'
import MuiButton from '@mui/material/Button'
import { Icon, IconButton, ListItem, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { getJuvoInfo } from '../../../store'
import { ClaimListModel, ClaimView, StdComponentArgs } from '../../../types'
import Claim, { ClaimsTxtChanged, DoneWithChanges } from '../ClaimList/Claim'
import { isUndefined } from '../../../utils/Undefined'

import './ClaimList.scss'
import {
  changeClaimView,
  claimsFromView,
  compress,
  format,
  uncondenseClaim,
} from './utils'

const ClaimList: React.FC<StdComponentArgs<ClaimListModel>> = props => {
  let { comp } = props
  const { onComponentChange } = props

  //TODO this lazily initializes the compressed ClaimsView[]
  // a better approach would be to move this to handling of incoming server messages,
  // however, currently we do not have a placeholder to idiosyncratically handle
  // results of platform.new http request that returns all the skeletons.
  // Doing this will make testing a bit complicated (test app would need to be used separately
  // with a skeleton first, and then a message with data would need to mocked separately).
  // For now this should do it.
  useEffect(() => {
    if (isUndefined(comp.internal_condensed_claims)) {
      comp = initializeClaims(comp)
      onComponentChange(comp)
    }
  })

  // Return something of a dummy to just get it running

  const condensed_claims: ClaimView[] = comp.internal_condensed_claims || []

  const addElem = () => {
    const newCondensedChildren = [
      ...condensed_claims,
      { ...createEmptyCondensedClaimModel() },
    ]
    onComponentChange({
      ...comp,
      internal_condensed_claims: newCondensedChildren,
    })
  }

  const removeElem = (index: number) => {
    const newCondensedChildren = condensed_claims.filter(
      (_, ix) => ix !== index,
    )
    const newChildren = claimsFromView(newCondensedChildren, comp.claims)
    console.log('new claims: ', newChildren)
    onComponentChange({
      ...comp,
      claims: newChildren,
      internal_condensed_claims: newCondensedChildren,
    })
  }

  return (
    <div>
      <div {...getJuvoInfo('ClaimList', comp)} className="claim-list-container">
        <Typography variant="body1">Claim Information</Typography>
        {condensed_claims.map((cp, index) => {
          const onClaimChange = (
            nc: ClaimsTxtChanged | DoneWithChanges,
          ): void => {
            switch (nc.changeType) {
              case 'done':
                return onComponentChange(
                  uncondenseClaim(nc.payload, index, comp),
                )
              case 'claimsTxtChange':
                return onComponentChange(
                  changeClaimView(nc.payload, index, comp),
                )
            }
          }
          //This is not really needed
          // const childProps: StdComponentArgs<any> =
          //   adjustStdComponentArgs(props, cp, onClaimChange)
          return (
            <ListItem
              key={index}
              component={Paper}
              elevation={4}
              className="claim-container"
            >
              <Box
                sx={{
                  display: 'inline-block',
                }}
              >
                <IconButton
                  className="delete-list-item-btn fa fa-trash claim-element"
                  juvo-action="list-delete"
                  color="inherit"
                  size="small"
                  onClick={() => removeElem(index)}
                />
                <Claim
                  key={index}
                  comp={cp}
                  onChange={onClaimChange}
                  onCommand={props.onCommand}
                />
              </Box>
            </ListItem>
          )
        })}
      </div>
      <div className="add-button-container">
        <MuiButton
          variant="text"
          startIcon={<Icon className="fa fa-plus" />}
          juvo-action="list-add"
          onClick={addElem}
        >
          Add Claim
        </MuiButton>
      </div>
    </div>
  )
}

export default ClaimList

export const createEmptyCondensedClaimModel = (): ClaimView => {
  return { claims: '', status: '', isIndependent: null }
}

//TODO this and basic functions that operate on ClaimListModel
// should be moved next to where the ClaimListModel is defined (Model.ts or a new module (Model/Claim.ts)
export const initializeClaims = (comp: ClaimListModel): ClaimListModel => {
  const claims = comp.claims || []
  const claimMapping = compress(claims)
  const condensedClaims = format(claimMapping)
  return { ...comp, internal_condensed_claims: condensedClaims }
}
