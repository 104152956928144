import React, { CSSProperties } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  InputBase,
  Popper,
  PopperProps,
  Theme,
  Tooltip,
  useTheme,
} from '@mui/material'
import FormHelperText from '@mui/material/FormHelperText'
import clsx from 'clsx'

import { getJuvoInfo } from '../../../store'
import {
  InputStateProps,
  isDefined,
  isUndefined,
  sanitizeInputName,
} from '../../../utils'
import { TextInputModel } from '../../../types'

const getSuggestions = (comp: TextInputModel): string[] => {
  // validate length because BE is [Text]
  if (comp.suggestions && comp.suggestions.length > 0) {
    return comp.suggestions
  } else {
    if ( comp.bound?.suggestions?.recommendation && comp.bound.suggestions.recommendation.length > 0) {
      return comp.bound.suggestions.recommendation
    } else {
      return []
    }
  }
}

/**
 * Currently serves both generic type="input", no sub-type and "textarea"
 */
const InputTextField: React.FC<{
  comp: TextInputModel
  multiline: boolean
  inputStateProps: InputStateProps
}> = ({ comp, multiline, inputStateProps }) => {
  const juvoInfo = getJuvoInfo('TextInput', comp)
  const label = comp.text || ''
  const recommendation = comp.recommendation
  const {
    value,
    setValue,
    handleBlur,
    handlersFromServer,
    hasErrors,
    errorMessage,
  } = inputStateProps
  const theme = useTheme()
  const inputName = sanitizeInputName(label)
  const inputValue = isDefined(value)
    ? value
    : isDefined(recommendation)
    ? recommendation
    : ''
  const isReadOnly = comp.input_options
    ? comp.input_options['read-only']
    : false
  // Display-only is an extra display option on top of read-only. If the input is not already read-only, then it cannot be display-only
  const isDisplayOnly = comp.input_options
    ? isReadOnly && comp.input_options['display-only']
    : false
  const isHidden = comp.input_options ? comp.input_options['hidden'] : false
  const isPassword = comp.input_options ? comp.input_options['password'] : false
  const suggestions = getSuggestions(comp)

  const getItemStyle = (
    theme: Theme,
    isDisplayOnly: boolean,
  ): CSSProperties => ({
    ...(isDisplayOnly && {
      borderRadius: '4px',
      padding: '12px 16px',
      background: theme.palette.secondary.main,
      alignItems: 'center',
      gap: '12px',
      flexDirection: 'row',
      label: {
        transform: 'none',
        fontSize: '0.8rem',
        color: theme.palette.grey[700],
      },
      input: {
        background: 'transparent !important',
        border: 'none !important',
        outline: 'none !important',
        color: theme.palette.primary.main,
        fontWeight: '500',
        padding: '0 !important',
        WebkitTextFillColor: 'unset !important',
      },
      '.juvo-input-base:after': {
        display: 'none',
      },
    }),
  })

  const getInputWidth = (myProps: PopperProps): number | undefined => {
    const anchor = myProps.anchorEl
    if (isDefined(anchor) && anchor instanceof HTMLElement) {
      const child = anchor.querySelector('input')
      if (isDefined(child)) {
        return child.clientWidth
      } else {
        return anchor.clientWidth
      }
    }
  }

  const getPopper = (props: PopperProps) => {
    const width = getInputWidth(props)
    return (
      <Popper
        {...props}
        placement="bottom-start"
        sx={{
          top: '-16px !important',
          ...(isDefined(width) && { width: `${width}px!important` }),
        }}
      />
    )
  }

  const getBaseTextInput = (params?: AutocompleteRenderInputParams) => (
    <FormControl
      error={hasErrors}
      variant="standard"
      fullWidth
      className="juvo-input-control"
      sx={{
        display: isHidden ? 'none' : 'flex',
        ...getItemStyle(theme, isDisplayOnly),
      }}
      ref={params?.InputProps.ref ?? undefined}
      {...juvoInfo}
    >
      <InputLabel shrink className="juvo-input-label">
        {label}
      </InputLabel>
      <Tooltip arrow title={comp.tooltip ?? ''}>
        <InputBase
          {...handlersFromServer}
          type={isPassword ? 'password' : 'text'}
          id={inputName}
          name={inputName}
          value={inputValue}
          disabled={isReadOnly}
          onChange={
            isDefined(params)
              ? undefined
              : event => setValue(event.target.value)
          }
          onBlur={handleBlur}
          multiline={multiline}
          inputProps={{
            ...params?.inputProps,
          }}
          className={clsx(
            'juvo-input-base',
            isDefined(recommendation) && isUndefined(value) && 'recommendation',
          )}
        />
      </Tooltip>
      {!isDisplayOnly && (
        <FormHelperText sx={{ visibility: hasErrors ? 'visible' : 'hidden' }}>
          {errorMessage || ' '}
        </FormHelperText>
      )}
    </FormControl>
  )

  return suggestions.length > 0 ? (
    <Autocomplete
      freeSolo
      id={`${inputName}-autocomplete`}
      options={suggestions}
      onInputChange={(_event, value) => setValue(value)}
      inputValue={inputValue}
      PopperComponent={props => getPopper(props)}
      renderInput={(params: AutocompleteRenderInputParams) =>
        getBaseTextInput(params)
      }
    />
  ) : (
    getBaseTextInput()
  )
}

export default InputTextField