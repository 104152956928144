import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import { Box } from '@mui/material'

import {
  AppRegistration,
  appRegistrationLoadingState,
} from '../../types'

import RocketFlight from './RocketFlight'

const Loading: React.FC<{ appReg: AppRegistration }> = ({ appReg }) => {
  const isLoading = appRegistrationLoadingState(appReg) === 'loading'

  return (
    <Backdrop
      open={isLoading}
      sx={{
        color: '#fff',
        marginBottom: '0 !important',
        zIndex: theme => theme.zIndex.appBar - 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <RocketFlight />
      </Box>
    </Backdrop>
  )
}

export default Loading
