import React from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { InputBase } from '@mui/material'
import FormHelperText from '@mui/material/FormHelperText'
import clsx from 'clsx'

import { NumberInputModel } from '../../../types'
import { getJuvoInfo } from '../../../store'
import {
  DisabledWheelProps,
  InputStateProps,
  isDefined,
  sanitizeInputName,
} from '../../../utils'

const NumberInput: React.ForwardRefRenderFunction<
  HTMLDivElement,
  {
    comp: NumberInputModel
    inputStateProps: InputStateProps
    disabledWheelProps: DisabledWheelProps
  }
> = ({ comp, inputStateProps, disabledWheelProps }, ref) => {
  const juvoInfo = getJuvoInfo('NumberInput', comp)
  const label = comp.text || ''
  const recommendation = comp.recommendation
  const { handleFocused } = disabledWheelProps

  const {
    value,
    setValue,
    handleBlur,
    handlersFromServer,
    hasErrors,
    errorMessage,
  } = inputStateProps
  const inputName = sanitizeInputName(label)

  return (
    <FormControl
      error={hasErrors}
      variant="standard"
      fullWidth
      className="juvo-input-control"
      {...juvoInfo}
    >
      <InputLabel shrink htmlFor={inputName} className="juvo-input-label">
        {label}
      </InputLabel>
      <InputBase
        {...handlersFromServer}
        ref={ref}
        type="number"
        id={inputName}
        name={inputName}
        value={
          isDefined(value)
            ? value
            : isDefined(recommendation)
            ? recommendation
            : ''
        }
        onChange={event => {
          setValue(event.target.value)
        }}
        onFocus={() => {
          handleFocused(true)
          handlersFromServer.onFocus && handlersFromServer.onFocus()
        }}
        onBlur={() => {
          handleFocused(false)
          handleBlur()
        }}
        className={clsx(
          'juvo-input-base',
          isDefined(recommendation) && !isDefined(value) && 'recommendation',
        )}
      />
      <FormHelperText sx={{ visibility: hasErrors ? 'visible' : 'hidden' }}>
        {errorMessage || ' '}
      </FormHelperText>
    </FormControl>
  )
}

export default React.forwardRef(NumberInput)
