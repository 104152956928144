import React, { forwardRef, useState } from 'react'
import Button from '@mui/material/Button'
import MuiDialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

import { AppPopup } from '../../types'
import { useOnSmallScreen } from '../../utils'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />
})

const Dialog: React.FC<{ data: AppPopup }> = ({ data }) => {
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    setOpen(false)
  }
  const onSmallScreen = useOnSmallScreen()

  return (
    <MuiDialog
      open={open}
      TransitionComponent={Transition}
      fullScreen={onSmallScreen}
      PaperProps={{ sx: { padding: '1rem 0' } }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="app-dialog"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ textAlign: 'center', fontSize: 24 }}
      >
        {data.popup_title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {data.popup_text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {data.popup_buttons.map(([label, action]) => (
          <Button
            variant="contained"
            key={action}
            onClick={handleClose}
            data-testid="app-dialog-btn"
          >
            {label}
          </Button>
        ))}
      </DialogActions>
    </MuiDialog>
  )
}

export default Dialog
