import { InitialAppData, isInitialAppData } from '../types'
import {
  betterFetchJson,
  Either,
  eitherChain,
  FetchError,
  getJuvoConfig,
  isDefined,
  left,
  Nullable,
  right,
  unexpectedFetchError,
} from '../utils'

const checkNewResult = (res: any): Either<FetchError, InitialAppData> => {
  if (isInitialAppData(res)) {
    return right(res)
  } else {
    console.log({
      source: 'checkNewResult',
      err: 'New Result parse err',
      dta: res,
    })
    return left(unexpectedFetchError('New Result parse err'))
  }
}

// TODO needs suggestions
// TODO this is all or nothing,  if one app fails to parse we error out
export const new_ = async (
  token: Nullable<string>,
  ctx: any,
): Promise<Either<FetchError, InitialAppData>> => {
  const { platformHttpUrl } = getJuvoConfig()
  const defheaders = {
    'Content-type': 'application/json',
  }
  const headers = isDefined(token) ? {...defheaders, Authorization: `Bearer ${token}`} : defheaders
  const res = await betterFetchJson(`${platformHttpUrl}/new`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(ctx),
  })

  return eitherChain(res, checkNewResult)
}

export const sample = (): InitialAppData => {
  return {
    guid: '',
    app_list: [],
  }
}
