import React from 'react'
import Typography from '@mui/material/Typography'
import MuiButton from '@mui/material/Button'
import Box from '@mui/material/Box'

import { User } from '../../types'

const Account: React.FC<{ user: User }> = ({ user }) => {
  return (
    <>
      <Box sx={{ paddingLeft: '6px' }}>
        <Typography variant="body1" data-testid="user-name">
          {user.userName}
        </Typography>
        <Typography variant="body2" data-testid="user-email">
          {user.userEmail}
        </Typography>
      </Box>
      <MuiButton
        variant="text"
        color="error"
        sx={{ marginTop: '1rem' }}
        data-testid="logout-btn"
      >
        Logout
      </MuiButton>
    </>
  )
}

export default Account
