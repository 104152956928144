import { Box, Button, Typography, useTheme } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import NotFoundSplash from './NotFoundSplash'

const NotFound: React.FC<{
    message: string,
    action?: () => void
  }> = ({ message, action }) => {

  const theme = useTheme();
  return (
    <>
      <Box sx={{
        textAlign: "center"
      }}>
        <Box sx={{
          width: "284px",
          margin: "40px auto",
          [theme.breakpoints.up('md')]: {
            width: "484px"
          },
        }}>
          <NotFoundSplash />
        </Box>
        <Box sx={{
          margin: "12px 0 24px"
        }}>
            <Typography variant="h2">Oops!</Typography>
            <Typography variant="h4">Something went wrong.</Typography>
        </Box>
        <Box sx={{
          fontWeight: "600",
          color: "grey"
        }}>Error: {message}</Box>
        <Box sx={{
          margin: "24px 0 0 0"
        }}>
          <Button variant='contained' component={Link} to="/" onClick={() => {action && action()}}>Go Home</Button>
        </Box>
      </Box>
    </>
  )
}

export default NotFound;