import React from 'react'

import Button from '../Button/Button'
import { AppRegistration, ButtonsModel, Command, Warning } from '../../../types'
import { getButtons, getJuvoInfo } from '../../../store'
import AppWarnings from '../AppWarnings/AppWarnings'
import { ValidationError } from '../../../store/Validation'
import './Buttons.scss'

const Buttons: React.FC<{
  comp: ButtonsModel
  onCommand: (cmd: Command) => void
  appInfo: [Warning, AppRegistration]
  validationState: {
    validationErrs: ValidationError[]
    setValidationErrs: (_: ValidationError[]) => void
  }
}> = ({ comp, onCommand, appInfo, validationState }) => {
  const buttons = getButtons(comp)
  const { validationErrs, setValidationErrs } = validationState

  return (
    <div {...getJuvoInfo('Buttons', comp)}>
      {validationErrs.length > 0 && (
        <AppWarnings warnings={appInfo[0]} validationErrs={validationErrs} />
      )}
      <div className="juvo-buttons">
        {buttons.map((button, index) => (
          <Button
            key={index}
            comp={button}
            onCommand={onCommand}
            setValidationErrs={setValidationErrs}
            app={appInfo[1]}
          />
        ))}
      </div>
    </div>
  )
}

export default Buttons
