import React from 'react'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'
import MuiRating from '@mui/material/Rating';
import Box from '@mui/material/Box'

import { Command, RatingModel } from '../../../types'
import { setComponentValue } from '../../../store'
import { getJuvoProps, isDefined, Nullable, ValidationProps } from '../../../utils'

const Rating: React.FC<{
  comp: RatingModel
  onCommand: (cmd: Command) => void
  onChange: (_: RatingModel) => void
  validationProps: ValidationProps
}> = ({ comp, onCommand, onChange, validationProps }) => {
  const handleChange = (e: any) => {
    // console.log("GOT0", {ev: e.target?.value})
    // target value is string "1" or up referring to rating postion.
    const newratingstr: Nullable<string>  = e.target?.value
    const ratingnum = isDefined(newratingstr) ? parseInt(newratingstr, 10): null
    const newval = isDefined(ratingnum) ? comp.options[ratingnum - 1]: null
    
    onChange(setComponentValue(comp)(newval))
  }

  // const modelval = comp.value || comp.recommendation

  const { eventHandlers, className, value, juvoInfo } = getJuvoProps(
    'Rating',
    comp,
    onCommand,
    handleChange,
  )

  // Note: rating wiget is 1 based, index is 0 based.  
  const ratingval = isDefined(value)? comp.options.indexOf(value) + 1: null

  //console.log("examine", {val: value, comp: comp})
  const { onBlur: onBlurFromServer, ...handlersFromServer } = eventHandlers
  const label = comp.text || ''
  const { handleVisited, hasErrors, errorMessage } = validationProps

  return (
    <FormControl
      {...juvoInfo}
      component="fieldset"
      error={hasErrors}
      sx={{ display: 'block', marginBottom: theme => theme.spacing(2) }}
    >
      <FormLabel component="legend">{label}</FormLabel>
        <Box
        sx={{
          // legend and styling currently not implemented
          // '& > legend': { mt: 2 },
        }}
        >
        <MuiRating
          name="simple-controlled"
          value={ratingval}
          // onChange={handleChange}
          max={comp.options.length}
          {...handlersFromServer}
          className={className}
          onBlur={() => {
            handleVisited()
            onBlurFromServer && onBlurFromServer()
          }}

        />
      </Box>
      {hasErrors && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

export default Rating
