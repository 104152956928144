import React from 'react'
import MuiButton from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Icon from '@mui/material/Icon'

import { AppRegistration, ButtonModel, Command } from '../../../types'
import { buttonEvents, getJuvoInfo } from '../../../store'
import { ValidationError } from '../../../store/Validation'

const Button: React.FC<{
  comp: ButtonModel
  onCommand: (cmd: Command) => void
  setValidationErrs: (_: ValidationError[]) => void
  app: AppRegistration
}> = ({ comp, onCommand, setValidationErrs, app }) => {
  const performValidations =
    comp.input_actions.find(ia => ia.event === 'Click')?.perform_validation ||
    false
  const evAttr: any = buttonEvents(
    comp,
    {type:'from-stablestate', fn: onCommand},
    performValidations,
    setValidationErrs,
    app,
  )

  if (evAttr) {
    return (
      <MuiButton
        variant={comp.variant ?? 'contained'}
        {...getJuvoInfo('Button', comp)}
        {...evAttr}
        endIcon={comp.icon && <Icon className={comp.icon} />}
      >
        {comp.text || 'Unknown'}
      </MuiButton>
    )
  } else {
    return (
      <Typography {...getJuvoInfo('Button.invalid', comp)} variant="body1">
        <strong>Invalid button event configuration</strong>
      </Typography>
    )
  }
}

export default Button
