import React from 'react'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'

import { Warning } from '../../../types'
import { getJuvoInfo } from '../../../store'
import { ValidationError } from '../../../store/Validation'
import './AppWarnings.scss'
import { Nullable } from '../../../utils'

const AppWarnings: React.FC<{
  warnings?: Nullable<Warning>
  validationErrs: ValidationError[]
}> = ({ validationErrs }) => {
  // TODO: presentation for warn is missing (low priority)

  //This is reverted to show all errors, since we are showing them only after user attepts to do an action, this shoud be now
  // a better approach.
  //before we were doing this:
  //const showErrors = (validationErrs.length > 0) ? [validationErrs[0]] : []
  const showErrors = validationErrs //(validationErrs.length > 0) ? [validationErrs[0]] : []

  if (showErrors.length > 0) {
    return (
      <List
        {...getJuvoInfo('AppWarnings', validationErrs)}
        className="juvo-error"
      >
        {showErrors.map((error, i) => (
          <ListItem key={i} disableGutters disablePadding>
            <ListItemText
              className="app-error"
              primary={`Issue with ${error.field_name}: ${error.validationMsg}`}
            />
          </ListItem>
        ))}
      </List>
    )
  } else {
    return <div className="no-app-errors" />
  }
}

export default AppWarnings
